import React from "react";
import Expression from "../Expression";

const SiteVistitorTd = ({ data }: { data: any }) => {
  return (
    <td className="text-center">
      <div className="d-flex">
        <small className="d-inline-block text-truncate text-muted">
          Current Week: &nbsp;&nbsp;
          <Expression
            oldValue={data.weeklyLeadsPrevious}
            value={data.weeklyLeadsCurrent}
            percentAgeValue={`${data.weeklyLeadsPercentChange || 0}%`}
          />
        </small>
      </div>
      <div className="d-flex">
        <small className="d-inline-block text-truncate text-muted">
          Current Month: &nbsp;&nbsp;
          <Expression
            oldValue={data.monthlyLeadsPrevious}
            value={data.monthlyLeadsCurrent}
            percentAgeValue={`${data.monthlyLeadsPercentChange || 0}%`}
          />
        </small>
      </div>
      <div className="d-flex">
        <small className="d-inline-block text-truncate text-muted">
          Campaign To Date: &nbsp;&nbsp;
          {Math.abs(data.bimonthlyLeadsPrevious + data.bimonthlyLeadsCurrent)}
        </small>
      </div>
    </td>
  );
};

export default SiteVistitorTd;
