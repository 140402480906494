const headers = [
  {
    sortable: false,
    name: "Account",
    description: `Targeted organization`,
  },

  // {
  //   sortable: true,
  //   name: "Active Sources",
  //   key: "fractionScore",
  //   description: `Number of sources identifying intent signals from a given organization`,
  // },
  // {
  //   sortable: true,
  //   name: "Aggregate Score",
  //   key: "unifiedScore",
  //   description: `A single, algorithm-derived score for a given organization, factoring all intent sources and signals`,
  // },
  {
    sortable: false,
    name: "Active Topics",
    description: `Topics being researched by a given organization, along with associated topic scores`,
  },
  {
    sortable: false,
    name: "Digital",
    description: `Digital Stats`,
  },
  {
    sortable: false,
    name: "Lead Generation",
    description: `Lead Stats`,
  },
  {
    sortable: false,
    name: "Website Activity",
    description: `Weekly and monthly website visits from a given organization, and percentage change from prior period`,
  },
  {
    sortable: false,
    name: "Active Locations",
    description: `Geographic locations of a given organization showing a high-level of intent signals`,
  },
];

export const headersForPublisherView = [
  {
    sortable: false,
    name: "Select",
  },
  {
    sortable: true,
    name: "Tier",
  },
  {
    sortable: false,
    name: "Account",
  },
  {
    sortable: true,
    name: "Rank",
  },
  {
    sortable: true,
    name: "Score",
  },
  {
    sortable: false,
    name: "Surging Topics",
  },
  {
    sortable: false,
    name: "Keywords",
  },
  {
    sortable: false,
    name: "Site Visitor",
  },
  {
    sortable: false,
    name: "Lead Sift",
  },
  {
    sortable: false,
    name: "bombora static data",
  },
  {
    sortable: false,
    name: "Top Locations",
  },
];

export default headers;

// $where = {
//   rootWhere: {
//     domain: ["google.com", "facebook.com"],
//     totalSurgingTopics: { $gt: 2 },
//   },
//   LeadAppendDetailKeywords: { keyword: ["botany"] },
//   LeadAppendDetailLocations: { loc: ["cincinnati area"] },
//   LeadAppendDetailTopics: { topic: ["plant tissue culture", "Cytology"] },
//   order: ["id", "DESC"],
// };
