import axios from "./axios-configured";

export const uploadFile = async (data: any) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axios.post(`/target-account/upload-file`, data, config);
};

export const getAllCompanyFiles = async (fileType = "domains") => {
  return axios.get(`/target-account/getFiles?fileType=${fileType}`);
};

export const targetAccountFileView = async (id: any, fileType = "domains") => {
  return axios.get(`/target-account/getFileRecords/${id}?fileType=${fileType}`);
};

export const deleteCompanyFile = async (id: number) => {
  return axios.delete(`/target-account/deleteFile/${id}`);
};

export const fetchTargetFiles = async (fileType = "domains") => {
  return axios.get(`/target-account/getFiles?fileType=${fileType}`);
};

export const applyFilters = async (id: number, payload: any) => {
  return axios.post(`/campaign-setup/${id}/data`, payload);
};

export const removeDomains = async (id: number, payload: any) => {
  return axios.post(`/target-account/deleteRecords/${id}`, payload);
};

export const getCampaignSetupData = async (
  id: number,
  query: any,
  sortId: any = 0,
  type: string = "campaign",
  size = 10
) => {
  if (typeof query !== "string") {
    query = JSON.stringify(query);
  }
  return axios.get(`/campaign-setup/${id}/data?type=${type}&$where=${query}`, {
    params: {
      sortId,
      size,
    },
  });
};

export const getCampaignSetupDataForAll = async (
  id: number,
  query: any,
  page: any = 1,
  type: string = "campaign"
) => {
  if (typeof query !== "string") {
    query = JSON.stringify(query);
  }
  return axios.get(
    `/campaign-setup/data/allPublisher?type=${type}&$where=${query}`,
    {
      params: {
        page,
      },
    }
  );
};

export const fetchCampaignKeys = async (type: String) => {
  return axios.get(`/campaign-setup/fetch/campaignKeys?type=${type}`);
};

export const fetchCampaignStaticValues = async (payload: any) => {
  return axios.get(`/campaign-setup/${payload.id}/campaignKey`);
};

export const fetchCampaignStaticValuesForAll = async () => {
  return axios.get(`/campaign-setup/filters/allPublisher`);
};

export const fetchCounts = async (
  id: number,
  query: any,
  page: any = 1,
  type: string = "campaign"
) => {
  return axios.get(`/campaign-setup/${id}/count`, {
    params: {
      page,
      type,
      $where: query,
    },
  });
};

export const fetchCountsForAllPublishers = async (
  query: any,
  page: any = 1,
  type: string = "campaign"
) => {
  return axios.get(`/campaign-setup/count/allPublisher`, {
    params: {
      page,
      type,
      $where: query,
    },
  });
};

export const fetchDomains = async (
  id: number,
  text: string,
  type: string = "campaign"
) => {
  return axios.get(`/campaign-setup/${id}/domains`, {
    params: {
      text,
      type,
    },
  });
};

export const fetchTargetAccountCount = async (campaignKeyId: any) => {
  return axios.get(`/target-account/getFileRecordsCount/${campaignKeyId}`);
};
