import React from "react";
import classes from "./styles/BwCampaignStats.module.css";
import BwStatsHeader from "./BwStatsHeader";
import { Divider, Spin, message } from "antd";
import BwStatsChart from "./BwStatsChart";
import BwStatsFooter from "./BwStatsFooter";
import BwStatsTopSection from "./BwStatsTopSection";
import {
  getReport,
  getChartReport,
  getCampaignsList,
  getLineItems,
} from "../../api/beeswax";
import BwMainStats from "./BwMainStats";
import { useSelector } from "react-redux";

const BwCampaignStatsAdmin = () => {
  const companies = useSelector((state: any) => state.companies.list);
  const isAdmin = useSelector((state: any) => state.auth.role === "admin");
  const [filters, setFilters] = React.useState<any>({ duration: "last week" });
  const [report, setReport] = React.useState<object>({});
  const [loading, setLoading] = React.useState(false);
  const [chartReport, setChartReport] = React.useState<any>([]);
  const [chartFields, setChartFields] = React.useState({
    firstParam: isAdmin ? "spend" : "revenue",
    secondParam: "impression",
    dateParam: "Day",
  });
  const [chartLoading, setChartLoading] = React.useState(false);
  const [chartLabels, setChartLabels] = React.useState({
    firstParam: "Spend",
    secondParam: "Impressions",
  });
  const [campaigns, setCampaignsList] = React.useState<any>([]);
  const [campaignLoading, setCampaignLoading] = React.useState(false);
  const [lineItems, setLineItems] = React.useState<any>([]);
  const [lineItemLoading, setLineItemLoading] = React.useState(false);
  const [companyId, setCompanyId] = React.useState(0);

  const setChartLabelsHandler = (key: any, value: any) => {
    setChartLabels({ ...chartLabels, [key]: value });
  };

  const fetchReportHandler = async () => {
    try {
      setLoading(true);
      // let filterObj: any = {};
      // if (Object.keys(filters).length) filterObj = { ...filterObj, ...filters };
      // if (!isAdmin && !filterObj.campaign_id)
      //   filterObj["campaign_id"] = campaigns?.[0]?.campaign_id;
      // if (isAdmin && companyId && !filterObj.campaign_id)
      //   filterObj["campaign_id"] = campaigns?.[0]?.campaign_id;
      const data: any = await getReport(filters);
      setLoading(false);
      if (data || data.length) {
        const statsObj = data[0];
        setReport({ ...statsObj });
      } else {
        setReport({});
      }
      setLoading(false);
    } catch (error) {
      message.error("Unable to fetch complete stats. Try again!");
      setLoading(false);
      console.log({ error });
    }
  };

  const fetchChartStatsHandler = async () => {
    try {
      setChartLoading(true);
      // let filterObj: any = {};
      // if (Object.keys(filters).length) filterObj = { ...filters };
      // filterObj = { ...filterObj, ...chartFields };
      // if (!isAdmin && !filterObj.campaign_id)
      //   filterObj["campaign_id"] = campaigns?.[0]?.campaign_id;
      // if (isAdmin && companyId && !filterObj.campaign_id)
      //   filterObj["campaign_id"] = campaigns?.[0]?.campaign_id;
      const data: any = await getChartReport({ ...filters, ...chartFields });
      setChartLoading(false);
      if (data || data.length) {
        setChartReport([...data]);
      } else {
        setChartReport([]);
      }
    } catch (error) {
      message.error("Unable to fetch complete stats. Try again!");
      setChartLoading(false);
      console.log({ error });
    }
  };

  const fetchCampaignsHandler = async () => {
    try {
      setCampaignLoading(true);
      const data: any = await getCampaignsList(companyId);
      if (data && data.length && data[0]) {
        setCampaignsList([...data]);
        if (!isAdmin || (isAdmin && companyId))
          setFilters({ ...filters, campaign_id: data[0]?.campaign_id });
        else setFilters({ ...filters, campaign_id: 0 });
      } else setCampaignsList([]);
      setCampaignLoading(false);
    } catch (error) {
      setCampaignLoading(false);
      console.log({ error });
    }
  };

  const fetchLineItemsHandler = async () => {
    try {
      setLineItemLoading(true);
      const data: any = await getLineItems(companyId);
      if (data && data.length && data[0]) setLineItems([...data]);
      else setLineItems([]);
      setLineItemLoading(false);
    } catch (error) {
      setLineItemLoading(false);
      console.log({ error });
    }
  };

  React.useEffect(() => {
    fetchCampaignsHandler();
  }, [companyId]);

  React.useEffect(() => {
    fetchLineItemsHandler();
  }, [companyId]);

  React.useEffect(() => {
    if (campaigns && campaigns.length) fetchReportHandler();
  }, [filters]);

  React.useEffect(() => {
    if (campaigns && campaigns.length) fetchChartStatsHandler();
  }, [chartFields, filters]);

  const setFilterHandler = (key: any, value: any, removeKey: any) => {
    setFilters({ ...filters, [key]: value, [removeKey]: "" });
  };

  const setChartFieldsHandler = (key: any, value: any) => {
    setChartFields({ ...chartFields, [key]: value });
  };

  return (
    <>
      {!lineItemLoading && !campaignLoading ? (
        <div className={classes.container}>
          <div className={classes.filterContainter}>
            <BwStatsHeader
              setFilterHandler={setFilterHandler}
              filters={filters}
            />
            <BwStatsTopSection
              lineItems={lineItems}
              setMainFilters={setFilters}
              mainFilters={filters}
              companies={companies}
              isAdmin={isAdmin}
              setCompanyId={setCompanyId}
              companyId={companyId}
              campaigns={campaigns}
            />
          </div>
          <BwMainStats
            classes={classes}
            report={report}
            loading={loading}
            isAdmin={true}
          />
          <Divider />
          {chartLoading ? (
            <div className={classes.chatLoading}>
              <Spin />
            </div>
          ) : (
            <BwStatsChart
              chartReport={chartReport}
              chartLabels={chartLabels}
              chartFields={chartFields}
            />
          )}
          <BwStatsFooter
            setChartFieldsHandler={setChartFieldsHandler}
            chartFields={chartFields}
            setChartLabelsHandler={setChartLabelsHandler}
            paramOptions={paramOptions}
          />
        </div>
      ) : (
        <div
          className="w-100 justify-content-center d-flex align-items-center bg-white"
          style={{ minHeight: "75vh" }}
        >
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

const paramOptions = [
  {
    title: "Spend",
    value: "spend",
  },
  {
    title: "Impressions",
    value: "impression",
  },
  {
    title: "Clicks",
    value: "clicks",
  },
  {
    title: "CTR",
    value: "ctr",
  },
  {
    title: "CPM",
    value: "cpm",
  },
  {
    title: "CPC",
    value: "cpc",
  },
  {
    title: "Conversions",
    value: "conversion_count",
  },
  {
    title: "CTC",
    value: "clickthrough_conversions",
  },
];

export default BwCampaignStatsAdmin;
