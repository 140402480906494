import React from "react";
import { MDBDataTable } from "mdbreact";
import TableLoader from "../../commons/loaders/TableLoader";
import { Link, withRouter } from "react-router-dom";

import { Popconfirm } from "antd";
import deleteIcon from "../../assest/images/delete.svg";

import { get, remove } from "../../api/campaign-setup";
// import { toast } from "react-toastify";
import { message } from "antd";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import CampaignNameModal from "./CampaignNameModal";
import { UploadOutlined } from "@ant-design/icons";

const columns = [
  {
    label: "Campaign Id",
    field: "campaignId",
    sort: "asc",
  },
  {
    label: "Campaign Name",
    field: "campaignName",
    sort: "asc",
  },
  // {
  //   label: "Target Account File",
  //   field: "targetAccountFile",
  //   sort: "asc",
  // },
  {
    label: "Freq.Cap Duration",
    field: "duration",
    sort: "asc",
  },
  {
    label: "Freq.Cap Impressions",
    field: "impressions",
    sort: "asc",
  },
  // {
  //   label: "Keyword File",
  //   field: "KeywordFile",
  //   sort: "asc",
  // },
  // {
  //   label: "Type",
  //   field: "type",
  //   sort: "asc",
  // },
  {
    label: "CreatedAt",
    field: "createdAt",
    sort: "asc",
  },
  {
    label: "Action",
    field: "action",
    sort: "disabled",
  },
];

class CampaignSetupViewList extends React.Component<any, any> {
  state = {
    accountDiscoveryFiles: [],
    data: { columns },
    loading: false,
    show: false,
    deleteFileId: NaN,
    editFileData: {},
    csvModal: false,
  };
  csvToggle = () => this.setState({ csvModal: !this.state.csvModal });

  $fetchAccountDiscoveryFiles = async () => {
    try {
      this.setState({ loading: true });
      let res = await get();

      await this.setState({ accountDiscoveryFiles: res.data, loading: false });
      this.createTableData();
    } catch (error) {}
  };

  componentDidMount() {
    this.$fetchAccountDiscoveryFiles();
  }

  deleteFile = async (id: any) => {
    try {
      await remove(id);
      this.setState({ show: false });
      this.$fetchAccountDiscoveryFiles();
      message.success("Successfully deleted!");
    } catch (error) {}
  };

  /* Prepare date object for the datatable */
  createTableData = () => {
    let { accountDiscoveryFiles } = this.state;
    let rows = accountDiscoveryFiles.map((file: any) => {
      let targetFileName = file.CampaignSettingTargetFiles.map((item: any) => {
        let name = item.TargetAccountFile.fileName;
        return name;
      });
      let KeywordFileName = file.keywordFiles.map((item: any) => {
        return item.fileName;
      });

      return {
        id: file.id,
        campaignId: (file.CampaignKey ? file.CampaignKey.key : null) || null,
        campaignName: file.CampaignKey ? file.CampaignKey.keyName : "",
        targetAccountFile: targetFileName.join(","),
        createdAt: new Date(file.createdAt).toLocaleString(),
        KeywordFile: KeywordFileName.join(","),
        type: file.CampaignKey.type,
        duration: file?.duration ?? "N/A",
        impressions: file?.impressions ?? "N/A",
        action: (
          <>
            {/* <Button
              to={`/target-account`}
              className="btn btn-sm mx-2"
              color="success"
              onClick={() => {
                this.props.history.push(
                  `/account-descovery/details/${file.id}`
                );
              }}
            >
              View
            </Button> */}
            {/* <Button color="info" size="sm">
              Download
            </Button> */}
            <Link
              to={`/campaign-setup/edit/${file.id}`}
              className="btn btn-sm btn-primary"
            >
              Edit
            </Link>
            <Popconfirm
              title="Are you sure delete this task?"
              onConfirm={() => {
                this.deleteFile(file.id);
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button
                color="bg-transparent"
                size="sm"
                className="border-0 shadow-none"
              >
                <img src={deleteIcon} width="18" alt="" />
              </Button>
            </Popconfirm>
          </>
        ),
      };
    });
    this.setState({ data: { columns, rows } });
    return <></>;
  };
  render(): JSX.Element {
    const { data }: { data: any } = this.state;
    return (
      <>
        {this.props.auth.Role.name === "admin" && (
          <>
            <Link
              to="/campaign-setup/create"
              className="btn btn-success btn-sm float-right"
            >
              Create
            </Link>
          </>
        )}
        {this.state.loading ? (
          <TableLoader columns={columns} />
        ) : (
          <MDBDataTable
            striped
            responsive
            noBottomColumns
            bordered
            hover
            data={data}
            small={true}
            className="mt-3"
          />
        )}
        <CampaignNameModal
          csvModal={this.state.csvModal}
          csvToggle={this.csvToggle}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  companies: state.companies.list,
});

export default connect(mapStateToProps)(withRouter(CampaignSetupViewList));
