import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import { message, Popconfirm, Select as AntSelect } from "antd";

/* Custom Components | Functions */
import columns from "./CampaignSettingsColList";
import deleteIcon from "../../assest/images/delete.svg";
import TableLoader from "../../commons/loaders/TableLoader";
import {
  getAllCampaignSettings,
  deleteCampaignSettingsEntry,
  downloadFile,
} from "../../api/campaignFiles";

const Status = ({
  searchValue,
  color,
}: {
  searchValue: string;
  color: string;
}): JSX.Element => {
  return <span style={{ color }}>{searchValue}</span>;
};

export class AllCampaignSettings extends Component<any, any> {
  state = {
    loading: false,
    data: {
      columns,
      rows: [],
    },
    settings: [],
    companies: [],
    companyId: "",
  };

  downloadFile = async (item: any) => {
    let url: any = await downloadFile({ id: item.id, type: "campaign" });
    window.open(url.data.pop(), "Download");
  };

  createTableData = () => {
    this.setState({ loading: true });
    let { settings } = this.state;
    let rows = settings.map((setting: any, index: any) => {
      return {
        name: (
          <p className="location">
            {setting.CampaignKey
              ? setting.CampaignKey.keyName ||
                `Campaign-${setting.CampaignKey.key}`
              : ""}
          </p>
        ),
        key: setting.CampaignKey ? setting.CampaignKey.key : "",
        status: (
          <>
            {setting.status !== "completed" &&
              setting.status !== "error" &&
              setting.status !== "pending" && (
                <Status color="#33b5e5" searchValue={"Processing..."} />
              )}
            {setting.status === "error" && (
              <Status color="#d9534f" searchValue="Error" />
            )}
            {setting.status === "pending" && (
              <span style={{ color: "#055697" }}> Pending</span>
            )}
            {setting.status === "completed" && (
              <Status color="#00c851" searchValue="Completed" />
            )}
          </>
        ),
        totalRecords: setting.totalRecords?.toLocaleString(),
        createdAt: new Date(setting.createdAt).toLocaleString(),
        completedAt: setting.completedAt
          ? new Date(setting.completedAt).toLocaleString()
          : "",
        action: (
          <>
            <Button
              disabled={
                setting.isCompleted &&
                !setting.hasError &&
                setting.downloadStatus === "ready"
                  ? false
                  : true
              }
              color="info"
              size="sm"
              onClick={() => {
                this.downloadFile(setting);
              }}
              className="btn"
            >
              Download
            </Button>

            <Button
              className="btn btn-sm mx-2"
              color="success"
              disabled={setting.isCompleted && !setting.hasError ? false : true}
              onClick={() => {
                this.props.dispatch({
                  type: "SET_LEAD_APPEND_ID",
                  payload: { leadAppendId: setting.leadAppendId },
                });
                this.props.history.push(`/campaigns/${setting.id}`);
              }}
            >
              View
            </Button>

            {this.props.role.campaigns &&
              this.props.role.campaigns.global === "CRUD" && (
                <Popconfirm
                  title="Are you sure delete this task?"
                  onConfirm={() => {
                    this.handleDelete(setting.id);
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    color="bg-transparent"
                    size="sm"
                    className="border-0 shadow-none"
                    style={{ padding: "5px" }}
                  >
                    <img src={deleteIcon} width="18" alt="" />
                  </Button>
                </Popconfirm>
              )}
          </>
        ),
      };
    });
    this.setState({ data: { columns, rows }, loading: false });
  };

  getCampaignSettings = async () => {
    try {
      await this.setState({ loading: true });
      let queryParams: any = { type: "campaign" };

      if (this.state.companyId) queryParams["companyId"] = this.state.companyId;
      let response = await getAllCampaignSettings(queryParams);
      await this.setState({ settings: response.data, loading: false });
      this.createTableData();
    } catch (error) {
      message.error("Oops,something went wrong!");
      this.setState({ loading: false });
    }
  };

  handleDelete = async (id: number | string) => {
    try {
      await deleteCampaignSettingsEntry(id);
      message.success("Record Deleted Successfully");
      this.getCampaignSettings();
    } catch (error) {
      message.error("Oops,something went wrong!");
    }
  };

  // onReProcessFile = async (rowIndex: number) => {
  //   try {
  //     const row: any = this.state.settings[rowIndex];
  //     const payload = {
  //       status: "pending",
  //       isCompleted: false,
  //       hasError: false,
  //       totalRecords: 0,
  //       errMsg: null,
  //     };
  //     await updateCampaign({
  //       id: row.id,
  //       payload,
  //     });
  //     let settings: any = this.state.settings;
  //     settings = { ...settings[rowIndex], ...payload };
  //     await this.setState({ settings: settings });
  //     message.success("Reprocessing");
  //     this.getCampaignSettings();
  //   } catch (error) {
  //     message.error("Oops,something went wrong!");
  //   }
  // };

  componentDidMount = async () => {
    try {
      await this.getCampaignSettings();
    } catch (error) {
      console.log("Error while laoding campaings", error);
    }
  };

  componentDidUpdate = async (prevProps: any, prevState: any) => {
    if (this.state.companyId !== prevState.companyId) {
      try {
        await this.getCampaignSettings();
      } catch (error) {
        console.log("Error while laoding campaings", error);
      }
    }
  };

  handleSelection = (key: any, value: any) => {
    this.setState({ [key]: value });
  };

  render() {
    let { data, loading, companyId } = this.state;
    return (
      <>
        <div className="mt-3 d-flex align-items-center justify-content-between">
          {this.props.isAdmin ? (
            <AntSelect
              placeholder="Please select"
              onChange={(value: any) => {
                this.handleSelection("companyId", value);
              }}
              value={this.state.companyId ?? ""}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children
                  ?.toLowerCase()
                  ?.indexOf(input?.toLowerCase()) >= 0
              }
              style={{ minWidth: "250px" }}
            >
              <AntSelect.Option value="">Company: All</AntSelect.Option>
              {this.props.companies.map((company: any) => (
                <AntSelect.Option value={company.id}>
                  {company.name}
                </AntSelect.Option>
              ))}
            </AntSelect>
          ) : null}
        </div>

        {loading ? (
          <TableLoader columns={columns} length={7} />
        ) : (
          <MDBDataTable
            striped
            bordered
            hover
            noBottomColumns
            responsive
            data={data}
            sortRows={[]}
            small={true}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  role: state.auth.Role.$permissions,
  isAdmin: state.auth.role === "admin",
  companies: state.companies.list,
});

export default connect(mapStateToProps)(withRouter(AllCampaignSettings));
