import { client } from "./axios-configured";
const prefixUrl = "/beeswax";

export const uploadFile = async (data: any) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
    baseURL: process.env.REACT_APP_BW_IP_UPLOAD_API_URL,
  };
  return client.post(`${prefixUrl}/upload-file`, data, config);
};

export const getFiles = async () => {
  return client.get(`${prefixUrl}/files`, {
    baseURL: process.env.REACT_APP_BW_IP_UPLOAD_API_URL,
  });
};

export const getReport = async (filters = {}) => {
  return client.post(`${prefixUrl}/report`, filters);
};

export const getChartReport = async (filters = {}) => {
  return client.post(`${prefixUrl}/charts-report`, filters);
};

export const getCampaignsList = async (companyId = 0) => {
  return client.get(
    companyId
      ? `${prefixUrl}/campaigns?type=campaigns&companyId=${companyId}`
      : `${prefixUrl}/campaigns?type=campaigns`
  );
};

export const getAdvertisersList = async () => {
  return client.get(`${prefixUrl}/advertisers`);
};

export const getLineItems = async (companyId?: any) => {
  return companyId
    ? client.get(`${prefixUrl}/line_items/${companyId}`)
    : client.get(`${prefixUrl}/line_items`);
};
