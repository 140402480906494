import { Col, Row, Spin } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  getAdStats,
  getCampaignStats,
  getTopDomainByClicks,
  getTopDomainByImpression,
  getTopDomainByUniqVisitor,
  getTopDomainByUniqVisitorWithImpression,
} from "../../api/abm-stats";
import MenuImg from "../../assest/images/menu.png";
import MetricsFiltersHeader from "../ABM/ABMDetails/MetricsFiltersHeader";
import MainStatsItem from "./MainStatsItem";
import SecondaryStatsItem from "./SecondaryStatsItem";
import "./styles/ABM.css";
import TopDomainCard from "./TopDomainCard";
import TopDomains from "./TopDomains";
import { useSelector } from "react-redux";
import moment from "moment";
import { getAssignedCampaigns } from "../../api/campaign-setup";
import { fetchTargetAccountCount } from "../../api/target-account";

const ABM = () => {
  const history = useHistory();
  const [campaigns, setCampaignsList] = React.useState<any>([]);
  const [filters, setFilters] = React.useState<any>({
    dayspan: 7,
    cf_requesttrackerid: null,
  });
  const [companyId, setCompanyId] = React.useState(0);
  const companies = useSelector((state: any) => state.companies.list);
  const isAdmin = useSelector((state: any) => state.auth.role === "admin");
  const mainStatsData: any = {
    topImpressions: [],
    topClicks: [],
    topUniqueSiteVisitors: [],
    topVisitorsWithImpression: [],
    uniqueVisitorsCount: 0,
    engagedVisitorsCount: 0,
    pageViewsCount: 0,
    totalAccounts: 0,
    accountsReached: 0,
    accountsVisited: 0,
    accountsEngaged: 0,
  };
  const [data, setData] = React.useState<any>(mainStatsData);
  const [loading, setLoading] = React.useState<any>({
    topDomainByClicks: false,
    topDomainByUniqueVisitorsImpression: false,
    topDomainByImpressions: false,
    topDomainByUniqueVisitors: false,
    campaignSummary: false,
    hybridSummary: false,
    campaignFetch: false,
    targetAccounts: false,
  });

  const fetchCampaignsHandler = async () => {
    try {
      setCampaignsList([]);
      setFilters({
        dayspan: 7,
        cf_requesttrackerid: null,
      });
      setLoading((prev: any) => {
        return { ...prev, campaignFetch: true };
      });
      let res = companyId
        ? await getAssignedCampaigns(companyId)
        : await getAssignedCampaigns();
      setCampaignsList(res.data && res.data.length ? [...res.data] : []);
      setLoading((prev: any) => {
        return { ...prev, campaignFetch: false };
      });
    } catch (error) {
      setLoading((prev: any) => {
        return { ...prev, campaignFetch: false };
      });
      console.log({ error });
    }
  };

  const fetchTopDomainsByClicks = async () => {
    try {
      setLoading((prev: any) => {
        return { ...prev, topDomainByClicks: true };
      });
      if (!filters.campaignid)
        filters["campaignid"] = campaigns?.[0]?.CampaignKey?.key ?? "-1";
      const res: any = await getTopDomainByClicks({
        ...filters,
        recordsperpage: 20,
        pagenumber: 1,
      });
      const row = res?.data?.[0]?.result?.Row;
      setData((prev: any) => {
        return { ...prev, topClicks: row && row.length ? [...row] : [] };
      });
      setLoading((prev: any) => {
        return { ...prev, topDomainByClicks: false };
      });
    } catch (error) {
      setLoading((prev: any) => {
        return { ...prev, topDomainByClicks: false };
      });
      console.log({ error });
    }
  };
  const fetchTopDomainsByImpressions = async () => {
    try {
      setLoading((prev: any) => {
        return { ...prev, topDomainByImpressions: true };
      });
      if (!filters.campaignid)
        filters["campaignid"] = campaigns?.[0]?.CampaignKey?.key ?? "-1";
      const res: any = await getTopDomainByImpression({
        ...filters,
        recordsperpage: 20,
        pagenumber: 1,
      });
      const row = res?.data?.[0]?.result?.Row;
      setData((prev: any) => {
        return { ...prev, topImpressions: row && row.length ? [...row] : [] };
      });
      setLoading((prev: any) => {
        return { ...prev, topDomainByImpressions: false };
      });
    } catch (error) {
      setLoading((prev: any) => {
        return { ...prev, topDomainByImpressions: false };
      });
      console.log({ error });
    }
  };
  const fetchTopDomainsByUniqueVisitors = async () => {
    try {
      setLoading((prev: any) => {
        return { ...prev, topDomainByUniqueVisitors: true };
      });
      if (!filters.campaignid)
        filters["campaignid"] = campaigns?.[0]?.CampaignKey?.key ?? "-1";
      const res: any = await getTopDomainByUniqVisitor({
        ...filters,
        recordsperpage: 20,
        pagenumber: 1,
      });
      const row = res?.data?.[0]?.result?.Row;
      setData((prev: any) => {
        return {
          ...prev,
          topUniqueSiteVisitors: row && row.length ? [...row] : [],
        };
      });
      setLoading((prev: any) => {
        return { ...prev, topDomainByUniqueVisitors: false };
      });
    } catch (error) {
      setLoading((prev: any) => {
        return { ...prev, topDomainByUniqueVisitors: false };
      });
      console.log({ error });
    }
  };
  const fetchTopDomainsByUniqueVisitorsImpression = async () => {
    try {
      setLoading((prev: any) => {
        return { ...prev, topDomainByUniqueVisitorsImpression: true };
      });
      if (!filters.campaignid)
        filters["campaignid"] = campaigns?.[0]?.CampaignKey?.key ?? "-1";
      const res: any = await getTopDomainByUniqVisitorWithImpression({
        ...filters,
        recordsperpage: 20,
        pagenumber: 1,
      });
      const row = res?.data?.[0]?.result?.Row;
      setData((prev: any) => {
        return {
          ...prev,
          topVisitorsWithImpression: row && row.length ? [...row] : [],
        };
      });
      setLoading((prev: any) => {
        return { ...prev, topDomainByUniqueVisitorsImpression: false };
      });
    } catch (error) {
      setLoading((prev: any) => {
        return { ...prev, topDomainByUniqueVisitorsImpression: false };
      });
      console.log({ error });
    }
  };

  const fetchCampaignStats = async () => {
    try {
      setLoading((prev: any) => {
        return { ...prev, campaignSummary: true };
      });
      const startDate = moment()
        .subtract(filters.dayspan, "day")
        .format("YYYYMMDD");
      const filterObj = {
        campaignid: filters.campaignid
          ? filters.campaignid
          : campaigns?.[0]?.CampaignKey?.key
          ? campaigns?.[0]?.CampaignKey?.key
          : "-1",
        startdateyyyymmdd: startDate,
        enddateyyyymmdd: moment().format("YYYYMMDD"),
        cf_requesttrackerid: filters.cf_requesttrackerid,
      };
      const res: any = await getCampaignStats(filterObj);
      const row = res?.data?.[0]?.result?.Row;
      setData((prev: any) => {
        return {
          ...prev,
          accountsReached: row && row.length ? row[0]?.domainswithimpcur : 0,
        };
      });
      setLoading((prev: any) => {
        return { ...prev, campaignSummary: false };
      });
    } catch (error) {
      setLoading((prev: any) => {
        return { ...prev, campaignSummary: false };
      });
      console.log({ error });
    }
  };

  const fetchAdStats = async () => {
    try {
      setLoading((prev: any) => {
        return { ...prev, hybridSummary: true };
      });
      if (!filters.campaignid)
        filters["campaignid"] = campaigns?.[0]?.CampaignKey?.key ?? "-1";
      const res: any = await getAdStats(filters);
      const row = res?.data?.[0]?.result?.Row;
      setData((prev: any) => {
        return {
          ...prev,
          accountsEngaged:
            row && row.length ? row[0]?.domainswithimpressionswith3pagescur : 0,
          accountsVisited:
            row && row.length ? row[0]?.domainswithimpressionscur : 0,
          pageViewsCount:
            row && row.length ? row[0]?.totalpageswithimpressionscur : 0,
          engagedVisitorsCount:
            row && row.length ? row[0]?.uniquevisitorswith3pagescur : 0,
          uniqueVisitorsCount:
            row && row.length ? row[0]?.uniquevisitorswithimpressionscur : 0,
        };
      });
      setLoading((prev: any) => {
        return { ...prev, hybridSummary: false };
      });
    } catch (error) {
      setLoading((prev: any) => {
        return { ...prev, hybridSummary: false };
      });
      console.log({ error });
    }
  };

  const getCampaignKeyId = () => {
    if (!filters.campaignid) {
      return campaigns?.[0]?.CampaignKey?.id;
    } else {
      const campaign = campaigns?.find(
        (item: any) => item?.CampaignKey?.key === filters.campaignid
      );
      return campaign?.CampaignKey?.id;
    }
  };

  const fetchTargetAccountCountHandler = async () => {
    try {
      setLoading((prev: any) => {
        return { ...prev, targetAccounts: true };
      });

      const campaignKeyId = getCampaignKeyId();
      const res = await fetchTargetAccountCount(campaignKeyId);
      setData((prev: any) => {
        return {
          ...prev,
          totalAccounts: res?.data?.totalProcessedRecords ?? 0,
        };
      });
      setLoading((prev: any) => {
        return { ...prev, targetAccounts: false };
      });
    } catch (error) {
      setLoading((prev: any) => {
        return { ...prev, targetAccounts: false };
      });
      console.log({ error });
    }
  };

  React.useEffect(() => {
    fetchCampaignsHandler();
    setData(mainStatsData);
  }, [companyId]);

  React.useEffect(() => {
    if (campaigns && campaigns.length) fetchTargetAccountCountHandler();
  }, [campaigns, filters]);

  React.useEffect(() => {
    if (campaigns && campaigns.length) fetchCampaignStats();
  }, [campaigns, filters]);

  React.useEffect(() => {
    if (campaigns && campaigns.length) fetchAdStats();
  }, [campaigns, filters]);

  React.useEffect(() => {
    if (campaigns && campaigns.length) fetchTopDomainsByClicks();
  }, [campaigns, filters]);

  React.useEffect(() => {
    if (campaigns && campaigns.length) fetchTopDomainsByImpressions();
  }, [campaigns, filters]);

  React.useEffect(() => {
    if (campaigns && campaigns.length) fetchTopDomainsByUniqueVisitors();
  }, [campaigns, filters]);

  React.useEffect(() => {
    if (campaigns && campaigns.length)
      fetchTopDomainsByUniqueVisitorsImpression();
  }, [campaigns, filters]);

  const getPercentage = (key: any) => {
    const perc = (data[key] / data.totalAccounts) * 100;
    return perc ?? 0;
  };

  return (
    <>
      {!loading.campaignFetch ? (
        <div className="bgColor">
          <div className="filtersHeader">
            <MetricsFiltersHeader
              campaigns={campaigns}
              setFilters={setFilters}
              filters={filters}
              type="abm"
              companies={companies}
              isAdmin={isAdmin}
              setCompanyId={setCompanyId}
              companyId={companyId}
            />
          </div>
          <Row>
            <Col xs={24} sm={12} lg={6}>
              <MainStatsItem
                title="Total Accounts"
                value={data.totalAccounts}
                percentage={data.totalAccounts ? 100 : 0}
                loading={loading.campaignSummary}
              />
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <MainStatsItem
                title="Accounts Reached"
                value={data.accountsReached}
                percentage={getPercentage("accountsReached")}
                loading={loading.campaignSummary}
                type="accounts-reached"
              />
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <MainStatsItem
                title="Accounts Visited"
                value={data.accountsVisited}
                percentage={getPercentage("accountsVisited")}
                loading={loading.hybridSummary}
                type="accounts-visited"
              />
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <MainStatsItem
                title="Accounts Engaged"
                value={data.accountsEngaged}
                percentage={getPercentage("accountsEngaged")}
                loading={loading.hybridSummary}
                type="accounts-engaged"
              />
            </Col>
          </Row>
          <div className="menuContainer primary">
            <div
              className="menuItem cp"
              onClick={() =>
                history.push(
                  `/abm-details?type=influenced-metrics&campaignId=${
                    filters.campaignid
                      ? filters.campaignid
                      : campaigns?.[0]?.CampaignKey?.key
                      ? campaigns?.[0]?.CampaignKey?.key
                      : "-1"
                  }`
                )
              }
            >
              <img src={MenuImg} alt="menu-img" className="img" />
              View Page Analytics
            </div>
            <div
              className="menuItem cp"
              onClick={() =>
                history.push(
                  `/abm-details?type=combined-metrics&campaignId=${
                    filters.campaignid
                      ? filters.campaignid
                      : campaigns?.[0]?.CampaignKey?.key
                      ? campaigns?.[0]?.CampaignKey?.key
                      : "-1"
                  }`
                )
              }
            >
              <img src={MenuImg} alt="menu-img" className="img" />
              View Campaign Summary
            </div>
            <div
              className="menuItem cp"
              onClick={() =>
                history.push(
                  `/abm-details?type=ad-metrics&campaignId=${
                    filters.campaignid
                      ? filters.campaignid
                      : campaigns?.[0]?.CampaignKey?.key
                      ? campaigns?.[0]?.CampaignKey?.key
                      : "-1"
                  }`
                )
              }
            >
              <img src={MenuImg} alt="menu-img" className="img" />
              View Ad Metrics
            </div>
            <div
              className="menuItem cp"
              onClick={() =>
                history.push(
                  `/abm-details?type=engagement-metrics&campaignId=${
                    filters.campaignid
                      ? filters.campaignid
                      : campaigns?.[0]?.CampaignKey?.key
                      ? campaigns?.[0]?.CampaignKey?.key
                      : "-1"
                  }`
                )
              }
            >
              <img src={MenuImg} alt="menu-img" className="img" />
              View Engagement Metrics
            </div>
          </div>
          {/* <Row>
        {revenueData.map((obj: any, index: any) => (
          <Col xs={24} sm={12} lg={8}>
            <SecondaryStatsItem key={index} item={obj} />
          </Col>
        ))}
      </Row>
      <div className="menuContainer primary">
        <div className="menuItem">
          <div className="setup">
            <img src={SettingsImg} alt="menu-img" className="img" />
            Set Up Criteria
          </div>
          <div>
            <img src={MenuImg} alt="menu-img" className="img" />
            View New Influenced Opportunity Metrics
          </div>
        </div>
      </div> */}
          <Row className="mb">
            <Col xs={24} sm={12} lg={8}>
              <SecondaryStatsItem
                title="Unique Visitors"
                amount={data.uniqueVisitorsCount}
                height="90px"
                loading={loading.hybridSummary}
                type="unique-visitors"
              />
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <SecondaryStatsItem
                title="Engaged Visitors"
                amount={data.engagedVisitorsCount}
                height="90px"
                loading={loading.hybridSummary}
                type="engaged-visitors"
              />
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <SecondaryStatsItem
                title="Total Page Views"
                amount={data.pageViewsCount}
                height="90px"
                loading={loading.hybridSummary}
                type="page-views"
              />
            </Col>
          </Row>
          <TopDomains />
          <Row style={{ paddingBottom: "50px" }}>
            <Col lg={6} md={12} sm={24}>
              <TopDomainCard
                domains={data.topImpressions}
                title="Impressions"
                number={40}
                loading={loading.topDomainsByClicks}
              />
            </Col>
            <Col lg={6} md={12} sm={24}>
              <TopDomainCard
                domains={data.topClicks}
                title="Clicks"
                number={40}
                loading={loading.topDomainByImpressions}
              />
            </Col>
            <Col lg={6} md={12} sm={24}>
              <TopDomainCard
                domains={data.topUniqueSiteVisitors}
                title="Unique Site Visitors"
                number={40}
                loading={loading.topDomainByUniqueVisitors}
              />
            </Col>
            <Col lg={6} md={12} sm={24}>
              <TopDomainCard
                domains={data.topVisitorsWithImpression}
                title="Visitors with Impression"
                number={40}
                loading={loading.topDomainByUniqueVisitorsImpression}
              />
            </Col>
          </Row>
        </div>
      ) : (
        <div
          className="w-100 justify-content-center d-flex align-items-center bg-white"
          style={{ minHeight: "75vh" }}
        >
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default ABM;
