import React from "react";
import { getCombinedMetrics } from "../../../api/abm-stats";
import Expression from "../../TargetAccount/Expression";
import moment from "moment";
import CustomAntTable from "../../../commons/CustomAntTable";
import _ from "lodash";
import InfluencedMetricsModal from "./InfluencedMetricsModal";

const CombinedMetrics = ({
  tableData,
  setTableData,
  changeLoadingHandler,
  campaigns,
  loading,
  filters,
  filterChangeHandler,
}: any) => {
  const antColumns = [
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      render: (item: any) => (
        <a
          style={{ color: "#2975CC", textDecoration: "underline" }}
          onClick={() => setSelectedDomain(item)}
        >
          {item}
        </a>
      ),
      fixed: "left",
    },
    {
      title: "Imp Curr",
      dataIndex: "impcur",
      key: "impcur",
      sorter: true,
      sortOrder: false,
    },
    {
      title: "Imp Prev",
      dataIndex: "impprev",
      key: "impprev",
      sorter: true,
      sortOrder: false,
    },
    {
      title: "Imp Delta",
      dataIndex: "impdelta",
      key: "impdelta",
      sorter: true,
      sortOrder: false,
    },
    {
      title: "Clicks",
      dataIndex: "clickscur",
      key: "clickscur",
      render: (item: any) => (
        <Expression
          value={item.clickscur}
          oldValue={item.clicksprev}
          percentAgeValue={item.clicksdelta.toString()}
        />
      ),
      sorter: true,
      sortOrder: false,
    },
    {
      title: "PgViews Curr",
      dataIndex: "pgviewcur",
      key: "pgviewcur",
      sorter: true,
      sortOrder: false,
    },
    {
      title: "InfPgViewCur",
      dataIndex: "infpgviewcur",
      key: "infpgviewcur",
      sorter: true,
      sortOrder: false,
    },
    {
      title: "PgViews Prev",
      dataIndex: "pgviewprev",
      key: "pgviewprev",
      sorter: true,
      sortOrder: false,
    },
    {
      title: "InfPgViewPrev",
      dataIndex: "infpgviewprev",
      key: "infpgviewprev",
      sorter: true,
      sortOrder: false,
    },
    {
      title: "PgViews Delta",
      dataIndex: "pgviewdelta",
      key: "pgviewdelta",
      sorter: true,
      sortOrder: false,
    },
    {
      title: "InfPgViewDelta",
      dataIndex: "infpgviewdelta",
      key: "infpgviewdelta",
      sorter: true,
      sortOrder: false,
    },

    {
      title: "Visitors Curr",
      dataIndex: "visitorscur",
      key: "visitorscur",
      sorter: true,
      sortOrder: false,
    },
    {
      title: "InfVisitorsCur",
      dataIndex: "infvisitorscur",
      key: "infvisitorscur",
      sorter: true,
      sortOrder: false,
    },
    {
      title: "Visitors Prev",
      dataIndex: "visitorsprev",
      key: "visitorsprev",
      sorter: true,
      sortOrder: false,
    },
    {
      title: "InfVisitorsPrev",
      dataIndex: "infvisitorsprev",
      key: "infvisitorsprev",
      sorter: true,
      sortOrder: false,
    },
    {
      title: "Visitors Delta",
      dataIndex: "visitorsdelta",
      key: "visitorsdelta",
      sorter: true,
      sortOrder: false,
    },

    {
      title: "InfVisitorsDelta",
      dataIndex: "infvisitorsdelta",
      key: "infvisitorsdelta",
      sorter: true,
      sortOrder: false,
    },
    {
      title: "CTR",
      dataIndex: "ctr",
      key: "ctr",
    },
  ];
  const [columns, setColumns] = React.useState(antColumns);
  const [selectedDomain, setSelectedDomain] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const fetchData = async () => {
    try {
      setTableData([]);
      changeLoadingHandler("tableData", true);
      // const inputData = formatSiteVisitorMetrics();
      let startDate = "";
      let endDate = "";
      if (filters.range) {
        const range = filters.range.split(",");
        startDate = range[0];
        endDate = range[1];
      } else {
        startDate = moment()
          .subtract(filters.dayspan, "day")
          .format("YYYYMMDD");
        endDate = moment().format("YYYYMMDD");
      }
      const filterObj = {
        ...filters,
        campaignid: filters.campaignid
          ? filters.campaignid
          : campaigns?.[0]?.CampaignKey?.key
          ? campaigns?.[0]?.CampaignKey?.key
          : "-1",
        startdateyyyymmdd: startDate,
        enddateyyyymmdd: endDate,
      };
      const res: any = await getCombinedMetrics(filterObj);
      const row: any = res?.data?.[0]?.result?.Row;
      let data = row.map((item: any, index: number) => {
        return {
          domain: item.domain,
          impcur: item.impcur,
          impprev: item.impprev,
          impdelta: item.impdelta,
          clickscur: {
            clickscur: item.clickscur,
            clicksprev: item.clicksprev,
            clicksdelta: item.clicksdelta,
          },
          infpgviewcur: item.infpgviewcur,
          infpgviewdelta: item.infpgviewdelta,
          infpgviewprev: item.infpgviewprev,
          infvisitorscur: item.infvisitorscur,
          infvisitorsdelta: item.infvisitorsdelta,
          infvisitorsprev: item.infvisitorsprev,

          visitorscur: item.visitorscur,
          visitorsprev: item.visitorsprev,
          visitorsdelta: item.visitorsdelta,
          pgviewcur: item.pgviewcur,
          pgviewprev: item.pgviewprev,
          pgviewdelta: item.pgviewdelta,
          ctr:
            parseFloat(((item.clickscur / item.impcur) * 100).toFixed(2)) + "%",
        };
      });
      setTableData(data ?? []);
      changeLoadingHandler("tableData", false);
    } catch (error) {
      changeLoadingHandler("tableData", false);
      console.log({ error });
    }
  };

  React.useEffect(() => {
    selectedDomain ? setOpen(true) : setOpen(false);
  }, [selectedDomain]);

  React.useEffect(() => {
    if (campaigns && campaigns.length) {
      fetchData();
    }
  }, [campaigns, filters]);

  return (
    <>
      <CustomAntTable
        columns={columns}
        setColumns={setColumns}
        tableData={tableData}
        loading={loading}
        filterChangeHandler={filterChangeHandler}
        filters={filters}
      />
      {open ? (
        <InfluencedMetricsModal
          isModalVisible={open}
          handleCancel={() => setSelectedDomain(null)}
          campaigns={campaigns}
          filters={filters}
          selectedDomain={selectedDomain}
        />
      ) : null}
    </>
  );
};

export default CombinedMetrics;
