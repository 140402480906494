import React from "react";
import { connect } from "react-redux";
import { Row, Col, Spinner } from "reactstrap";

import NewUserForm from "./NewUserForm";
import RenderToast from "../../commons/RenderToast";
import RoleMangementForm from "./RoleMangementForm";

/* API functions */
import { setUpUser, editUser } from "../../api/user";
import { fetchCompany } from "../../api/company";
import compact from "../../utility/compact";
import { passwordandConfirmpass } from "../../utility/validations";
// import { toast } from "react-toastify";
import { message } from "antd";
// import { editUser } from "../../redux/user/actions";
import { fetchCompanies } from "../../redux/companies/actions";
import { getAdvertisersList } from "../../api/beeswax";

class CreateUser extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      modal: false,
      companyName: "",
      name: "",
      confirmPassword: "",
      email: "",
      password: "",
      bwAccountId: process.env.REACT_APP_BW_ACCOUNT_ID,
      errorMsg: "",
      profileURl: "",
      userCompany: "",
      sendLinkInEmail: false,
      roleName: "",
      userIdForEditUser: null,
      linkLoading: false,
      seletedCompanyId: NaN,

      subAccountRole: "R",
      allowSubAccounts: true,

      companyPermission: "R",
      allowCompanyCreate: false,

      campaignFiles: [],
      campaignsRole: "R",
      allowCampaigns: false,

      allowTargetFiles: false,
      targetFileRole: "R",
      targetAccounts: [],

      allowAudienceManager: false,
      audiencePermission: "R",
      audienceManagerFiles: [],

      accountDiscoveries: [],
      allowAccountDiscovery: false,
      accountDiscoveryPermission: "R",

      campaignSetupRole: "R",
      allowCampaignSetup: false,
      campaignSetupFiles: [],

      publisherViewRole: "R",
      allowPublisherView: false,
      publisherViewFiles: [],
      showAdvertisers: true,
      advertisers: [],
      advertiserIds: [],

      allowABMStatsView: false,
      abmStatsViewRole: "R",

      allowCampaignStatsView: false,
      campaignStatsViewRole: "R",

      allowCampaignStatsAdminView: false,
      campaignStatsAdminViewRole: "R",
    };
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  createReactSelectValue(value: any) {
    let options: any = [];
    value.forEach((item: any) => {
      let option = {
        value: item.id,
        label: item.key,
      };
      options.push(option);
    });
    return options;
  }

  setEditUserValueOnState = () => {
    const {
      email,
      Username: name,
      companyId,
      bwAccountId,
      $permissions,
      id: userIdForEditUser,
    } = this.props.editUserData;
    this.setState({ email, name, bwAccountId, userIdForEditUser, companyId });
    if ($permissions.subAccounts) {
      this.setState({
        allowSubAccounts: true,
        subAccountRole: $permissions.subAccounts.global,
      });
    } else {
      this.setState({
        allowSubAccounts: false,
      });
    }
    // if ($permissions.campaignFiles) {
    //   let campaignFiles = [];
    //   for (let fileKey in $permissions.campaignFiles) {
    //     if (fileKey !== "global") {
    //       campaignFiles.push(fileKey);
    //     }
    //   }
    //   this.setState({
    //     allowCampaigns: true,
    //     campaignsRole: $permissions.campaignFiles.global,
    //     campaignFiles: [...campaignFiles],
    //   });
    // }
    if ($permissions.company) {
      this.setState({
        allowCompanyCreate: true,
        companyPermission: $permissions.company.global,
      });
    }

    // it is actully for audiance manager but key is targetaccount
    if ($permissions.targetAccounts) {
      let audienceManagerFiles = [];
      for (let fileKey in $permissions.targetAccounts) {
        if (fileKey !== "global") {
          audienceManagerFiles.push(fileKey);
        }
      }
      this.setState({
        allowAudienceManager: true,
        audiencePermission: $permissions.targetAccounts.global,
        audienceManagerFiles,
      });
    }
    if ($permissions.accountDiscoveries) {
      let permisson = $permissions.accountDiscoveries.global;
      let accountDiscoveries = [];
      for (let fileKey in $permissions.accountDiscoveries) {
        if (fileKey !== "global") {
          accountDiscoveries.push(fileKey);
        }
      }
      this.setState({
        allowAccountDiscovery: true,
        accountDiscoveryPermission: permisson,
        accountDiscoveries,
      });
    }

    // if ($permissions.targetAccounts) {
    //   let permisson = $permissions.targetAccounts.global;
    //   this.setState({
    //     allowTargetFiles: true,
    //     targetFileRole: permisson,
    //   });
    // }

    if ($permissions.campaigns) {
      let permisson = $permissions.campaigns.global;
      let campaignFiles = [];
      for (let fileKey in $permissions.campaigns) {
        if (fileKey !== "global") {
          campaignFiles.push(fileKey);
        }
      }
      this.setState({
        allowCampaignSetup: true,
        campaignSetupRole: permisson,
        campaignSetupFiles: [...campaignFiles],
      });
    }
    if ($permissions.publishers) {
      let permisson = $permissions.publishers.global;
      let publisherViewFiles = [];
      for (let fileKey in $permissions.publishers) {
        if (fileKey !== "global") {
          publisherViewFiles.push(fileKey);
        }
      }
      this.setState({
        allowPublisherView: true,
        publisherViewRole: permisson,
        publisherViewFiles,
      });
    }
    if ($permissions.abmStats) {
      this.setState({
        allowABMStatsView: true,
        abmStatsViewRole: $permissions.abmStats.global,
      });
    }
    if ($permissions.campaignStats) {
      this.setState({
        allowCampaignStatsView: true,
        campaignStatsViewRole: $permissions.campaignStats.global,
      });
    }
    if ($permissions.campaignStatsAdmin) {
      this.setState({
        allowCampaignStatsAdminView: true,
        campaignStatsAdminViewRole: $permissions.campaignStatsAdmin.global,
      });
    }
  };

  fetchAdvertisersList = async () => {
    try {
      const data: any = await getAdvertisersList();
      let options = data.map((value: any) => {
        return { label: value.advertiser_name, value: value.advertiser_id };
      });
      this.setState({ advertisers: options && options.length ? options : [] });
    } catch (error) {
      console.log({ error });
    }
  };

  componentDidMount = async () => {
    try {
      if (
        this.props.editUserData &&
        Object.keys(this.props.editUserData).length
      ) {
        await this.setEditUserValueOnState();
      }
      if (this.props.auth.companyId) {
        let response = await fetchCompany({
          companyId: this.props.auth.companyId,
        });
        this.setState({ userCompany: response.data.name });
      }
      await this.fetchAdvertisersList();
    } catch (error) {}
  };

  componentWillUnmount = async () => {
    this.props.dispatch({
      type: "CLEAR_USER",
    });
  };

  resetForms = () => {
    this.setState({
      loading: false,
      companyName: "",
      name: "",
      bwAccountId: process.env.REACT_APP_BW_ACCOUNT_ID,
      confirmPassword: "",
      email: "",
      password: "",
      errorMsg: "",
      userCompany: "",
      sendLinkInEmail: false,
      roleName: "",
      allowSubAccounts: true,
      subAccountRole: "R",
      allowCampaigns: false,
      allowCompanyCreate: false,

      companyPermission: "R",
      campaignsRole: "R",
      campaignFiles: [],

      //   state for target files
      allowTargetFiles: false,
      targetFileRole: "R",
      targetAccounts: [],
      //   state for audience manger
      allowAudienceManager: false,
      audiencePermission: "R",
      audienceManager: [],

      accountDiscovery: [],
      allowAccountDiscovery: false,
      accountDiscoveryPermission: "R",

      campaignSetupRole: "R",
      allowCampaignSetup: false,
      campaignSetupFiles: [],

      allowPublisherView: false,
      publisherViewFiles: [],
      publisherViewRole: "R",

      allowABMStatsView: false,
      abmStatsViewRole: "R",

      allowCampaignStatsView: false,
      campaignStatsViewRole: "R",

      allowCampaignStatsAdminView: false,
      campaignStatsAdminViewRole: "R",

      advertiserIds: [],
    });
  };

  editUserPayloadGenerator = () => {
    let {
      email,
      password,
      bwAccountId,
      name: Username,
      userIdForEditUser: userId,
      companyPermission,
    } = this.state;

    let payload: any = {
      email,
      Username,
      bwAccountId,
      password,
      userId,
      Role: {
        $permissions: {},
      },
    };

    // let campaignFiles: any = {
    //   global: this.state.allowCampaigns ? "R" : null,
    // };

    // this.state.campaignFiles.forEach((file: any, index: any) => {
    //   campaignFiles[file] = "R";
    // });
    // if (this.state.allowCampaigns) {
    //   payload.Role = {
    //     ...payload.Role,
    //     $permissions: {
    //       ...payload.Role.$permissions,
    //       campaignFiles: campaignFiles,
    //     },
    //   };
    // }
    //this payload for publisher view which is same as campaign file
    let publisher: any = {
      global: this.state.allowPublisherView
        ? this.state.publisherViewRole
        : null,
    };

    this.state.publisherViewFiles.forEach((file: any, index: any) => {
      publisher[file] = this.state.publisherViewRole;
    });
    if (this.state.allowPublisherView) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          publishers: publisher,
        },
      };
    }

    // this payload for campaign setup which is placed on the last in satup form
    let campaignsetupFiles: any = {
      global: this.state.allowCampaignSetup
        ? this.state.campaignSetupRole
        : null,
    };

    this.state.campaignSetupFiles.forEach((file: any, index: any) => {
      campaignsetupFiles[file] = this.state.campaignSetupRole;
    });
    if (this.state.allowCampaignSetup) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          campaigns: campaignsetupFiles,
        },
      };
    }
    // here end of the campaign setup payload

    let discoveryFile: any = {
      global: this.state.allowAccountDiscovery
        ? this.state.accountDiscoveryPermission
        : null,
    };
    if (this.state.accountDiscoveries && this.state.accountDiscoveries.length) {
      this.state.accountDiscoveries.forEach((file: any) => {
        discoveryFile[file] = this.state.accountDiscoveryPermission;
      });
    }

    if (this.state.allowAccountDiscovery) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          accountDiscoveries: discoveryFile,
        },
      };
    }

    let audienceFile: any = {
      global: this.state.allowAudienceManager
        ? this.state.audiencePermission
        : null,
    };
    if (
      this.state.audienceManagerFiles &&
      this.state.audienceManagerFiles.length
    ) {
      this.state.audienceManagerFiles.forEach((file: any) => {
        audienceFile[file] = this.state.audiencePermission;
      });
    }

    if (this.state.allowAudienceManager) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          targetAccounts: audienceFile,
        },
      };
    }

    // let targetFiles: any = {
    //   global: this.state.allowTargetFiles ? this.state.targetFileRole : null,
    // };

    // this.state.targetAccounts.forEach((file: any) => {
    //   targetFiles[file] = this.state.targetFileRole;
    // });

    // if (this.state.allowTargetFiles) {
    //   payload.Role = {
    //     ...payload.Role,
    //     $permissions: {
    //       ...payload.Role.$permissions,
    //       targetAccounts: targetFiles,
    //     },
    //   };
    // }

    if (this.state.allowCompanyCreate) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          company: { global: companyPermission },
        },
      };
    }
    if (this.state.allowSubAccounts) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          subAccounts: {
            global: this.state.subAccountRole,
          },
        },
      };
    }

    if (this.state.allowCampaignStatsView) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          campaignStats: {
            global: this.state.campaignStatsViewRole,
          },
        },
      };
    }

    if (this.state.allowABMStatsView) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          abmStats: {
            global: this.state.abmStatsViewRole,
          },
        },
      };
    }

    if (this.state.allowCampaignStatsAdminView) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          campaignStatsAdmin: {
            global: this.state.campaignStatsAdminViewRole,
          },
        },
      };
    }

    return payload;
  };

  payloadGenerator = () => {
    let {
      email,
      password,
      name: Username,
      bwAccountId,
      seletedCompanyId,
      companyName,
      companyPermission,
      sendLinkInEmail,
      advertiserIds,
    } = this.state;
    let payload: any = {
      email,
      Username,
      password,
      bwAccountId,
      sendLinkInEmail: sendLinkInEmail,
      companyId: seletedCompanyId,
      Company: { name: companyName },
      roleId: null,
      Role: {
        name: this.state.roleName || `auto-created-${Date.now()}`,
        $permissions: {},
      },
      ...(!seletedCompanyId &&
        advertiserIds.length && {
          advertiserIds: advertiserIds.map((item: any) => item.value),
        }),
    };

    // let targetFiles: any = {
    //   global: this.state.allowTargetFiles ? this.state.targetFileRole : null,
    // };

    // this.state.targetAccounts.forEach((file: any, index: any) => {
    //   targetFiles[file] = this.state.targetFileRole;
    // });

    // if (this.state.allowTargetFiles) {
    //   payload.Role = {
    //     ...payload.Role,
    //     $permissions: {
    //       ...payload.Role.$permissions,
    //       targetAccounts: targetFiles,
    //     },
    //   };
    // }

    // let campaignFiles: any = {
    //   global: this.state.allowCampaigns ? this.state.campaignsRole : null
    // };

    // this.state.campaignFiles.forEach((file: any, index: any) => {
    //   campaignFiles[file] = this.state.campaignsRole;
    // });
    // if (this.state.allowCampaigns) {
    //   payload.Role = {
    //     ...payload.Role,
    //     $permissions: {
    //       ...payload.Role.$permissions,
    //       campaign: campaignFiles
    //     }
    //   };
    // }

    //this payload for publisher view which is same as campaign file
    let publisher: any = {
      global: this.state.allowPublisherView
        ? this.state.publisherViewRole
        : null,
    };

    this.state.publisherViewFiles.forEach((file: any, index: any) => {
      publisher[file] = this.state.publisherViewRole;
    });
    if (this.state.allowPublisherView) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          publishers: publisher,
        },
      };
    }

    // this payload for campaign setup which is placed on the last in satup form
    let campaignsetupFiles: any = {
      global: this.state.allowCampaignSetup
        ? this.state.campaignSetupRole
        : null,
    };

    this.state.campaignSetupFiles.forEach((file: any, index: any) => {
      campaignsetupFiles[file] = this.state.campaignSetupRole;
    });
    if (this.state.allowCampaignSetup) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          campaigns: campaignsetupFiles,
        },
      };
    }
    // here end of the campaign setup payload

    if (this.state.allowCompanyCreate) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          company: { global: companyPermission },
        },
      };
    }
    if (this.state.allowSubAccounts) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          subAccounts: {
            global: this.state.subAccountRole,
          },
        },
      };
    }
    let discoveryFile: any = {
      global: this.state.allowAccountDiscovery
        ? this.state.accountDiscoveryPermission
        : null,
    };

    /* 
      @suleman please review this it breaks when we edit user and not select
      any file for now I'm adding a check to get resolve this 
    */
    if (this.state.accountDiscoveries && this.state.accountDiscoveries.length) {
      this.state.accountDiscoveries.forEach((file: any) => {
        discoveryFile[file] = this.state.accountDiscoveryPermission;
      });
    }

    if (this.state.allowAccountDiscovery) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          accountDiscoveries: discoveryFile,
        },
      };
    }
    let audienceFile: any = {
      global: this.state.allowAudienceManager
        ? this.state.audiencePermission
        : null,
    };
    if (
      this.state.audienceManagerFiles &&
      this.state.audienceManagerFiles.length
    ) {
      this.state.audienceManagerFiles.forEach((file: any) => {
        audienceFile[file] = this.state.audiencePermission;
      });
    }

    if (this.state.allowAudienceManager) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          targetAccounts: audienceFile,
        },
      };
    }
    // if (this.state.sendLinkInEmail) {
    //   payload.Role = {
    //     ...payload.Role,
    //     $permissions: {
    //       ...payload.Role.$permissions,
    //       sendLinkInEmail: { global: sendLinkInEmail }
    //     }
    //   };
    // }

    if (this.state.allowCampaignStatsView) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          campaignStats: {
            global: this.state.campaignStatsViewRole,
          },
        },
      };
    }

    if (this.state.allowABMStatsView) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          abmStats: {
            global: this.state.abmStatsViewRole,
          },
        },
      };
    }

    if (this.state.allowCampaignStatsAdminView) {
      payload.Role = {
        ...payload.Role,
        $permissions: {
          ...payload.Role.$permissions,
          campaignStatsAdmin: {
            global: this.state.campaignStatsAdminViewRole,
          },
        },
      };
    }

    return payload;
  };

  fetchCompanySuccess = async (res: any) => {};
  fetchCompanyFailure = async (error: any) => {};

  createUser = async () => {
    try {
      this.setState({ errorMsg: "", profileURl: "" });
      if (this.validator()) {
        this.setState({ linkLoading: true });
        let payload = this.payloadGenerator();
        console.log({ payload });
        let response = await setUpUser(payload);
        this.setState({
          profileURl: response.data.completePrfileUser,
          errorMsg: null,
          seletedCompanyId: response.data.user.companyId,
          linkLoading: false,
        });
        if (!payload.companyId) {
          this.props.dispatch(
            fetchCompanies(
              { text: "" },
              (res: any) => this.fetchCompanySuccess(res),
              (error: any) => this.fetchCompanyFailure(error)
            )
          );
        }
        this.resetForms();
      }
    } catch (error) {
      if (error && error.response && error.response.data.message) {
        this.setState({
          errorMsg: error.response.data.message,
          linkLoading: false,
        });
      } else {
        this.setState({
          errorMsg: "Something went wrong",
          linkLoading: false,
        });
      }
      console.log(error);
    }
  };
  $userEdit = async () => {
    try {
      let validate = passwordandConfirmpass(
        this.state.password,
        this.state.confirmPassword
      );
      if (validate.status) {
        let payload = this.editUserPayloadGenerator();
        payload = compact(payload);
        await editUser(payload);
        message.success("User Successfully Update");
        this.props.history.push("/setup/users");
        this.props.dispatch({
          type: "SET_USER_FOR_EDIT",
          payload: {},
        });
      } else {
        this.setState({ errorMsg: validate.message });
      }
    } catch (error) {}
  };

  validator = () => {
    /* Validation before payload generator */
    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>() [\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let { email, seletedCompanyId, companyName, confirmPassword, password } =
      this.state;
    if (!companyName && !seletedCompanyId) {
      this.setState({ errorMsg: "Company must be selected" });
      return false;
    } else if (email && !emailRegex.test(email)) {
      this.setState({ errorMsg: "Invalid email address" });
      return false;
    } else if (password !== confirmPassword) {
      this.setState({ errorMsg: "Password mismatch" });
      return false;
    }
    return true;
  };

  //   all handle functions

  handleChange = (event: any) => {
    if (event.target.type === "checkbox") {
      this.setState({
        [event.target.name]: event.target.checked,
      });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  handleCompanySelect = (data?: any) => {
    this.setState({ profileURl: "" });
    if (!data) {
      return this.setState({
        companyName: "",
        seletedCompanyId: NaN,
        showAdvertisers: true,
      });
    }
    if (data.id) {
      this.setState({
        companyName: data.name,
        seletedCompanyId: data.id,
        showAdvertisers: false,
        advertiserIds: [],
      });
    } else {
      this.setState({
        companyName: data.name,
        seletedCompanyId: NaN,
        showAdvertisers: true,
      });
    }
  };

  handleSelectedFiles = (key: any, value: any) => {
    if (value) {
      let fileKeys = value.map((fileOption: any) => {
        return fileOption.value;
      });
      this.setState({ [key]: fileKeys });
    } else {
      this.setState({ [key]: [] });
    }
  };
  // end handle functions

  handleSelect = (value: any, key: any) => {
    this.setState({ [key]: value });
  };

  render() {
    return (
      <div>
        <RenderToast />
        <Row className="" style={{ background: "#F4F5F6" }}>
          <NewUserForm
            /* Users details */
            email={this.state.email || ""}
            password={this.state.password}
            confirmPassword={this.state.confirmPassword}
            name={this.state.name || ""}
            bwAccountId={this.state.bwAccountId || ""}
            /* functions */
            handleCompanySelect={this.handleCompanySelect}
            handleChange={this.handleChange}
            /* Others */
            companyName={this.state.userCompany}
            errorMsg={this.state.errorMsg}
            sendLinkInEmail={this.state.sendLinkInEmail}
            profileURl={this.state.profileURl}
            advertisers={this.state.advertisers}
            advertiserIds={this.state.advertiserIds}
            showAdvertisers={this.state.showAdvertisers}
            handleSelect={this.handleSelect}
            auth={this.props.auth}
            editUser={this.props.editUserData}
            companies={this.props.companies}
          />

          <RoleMangementForm
            allowSubAccounts={this.state.allowSubAccounts}
            handleChange={this.handleChange}
            roleName={this.state.roleName}
            subAccountRole={this.state.subAccountRole}
            campaignsRole={this.state.campaignsRole}
            allowCampaigns={this.state.allowCampaigns}
            allowCompanyCreate={this.state.allowCompanyCreate}
            comapainsFiles={this.state.campaignFiles}
            companyPermission={this.state.companyPermission}
            allowTargetFiles={this.state.allowTargetFiles}
            targetFileRole={this.state.targetFileRole}
            allowAudienceManager={this.state.allowAudienceManager}
            allowAccountDiscovery={this.state.allowAccountDiscovery}
            audiencePermission={this.state.audiencePermission}
            accountDiscoveryPermission={this.state.accountDiscoveryPermission}
            campaignSetupRole={this.state.campaignSetupRole}
            allowCampaignSetup={this.state.allowCampaignSetup}
            handleSelectedFiles={this.handleSelectedFiles}
            allowPublisherView={this.state.allowPublisherView}
            publisherViewRole={this.state.publisherViewRole}
            campaignSetupFiles={this.state.campaignSetupFiles}
            publisherViewFiles={this.state.publisherViewFiles}
            accountDiscoveries={this.state.accountDiscoveries}
            audienceManagerFiles={this.state.audienceManagerFiles}
            allowCampaignStatsView={this.state.allowCampaignStatsView}
            allowABMStatsView={this.state.allowABMStatsView}
            allowCampaignStatsAdminView={this.state.allowCampaignStatsAdminView}
            auth={this.props.auth}
            editUser={this.props.editUserData}
          />
        </Row>
        <Row style={{ background: "#F4F5F6" }}>
          <Col className=" mt-3 mb-5">
            <>
              {this.state.loading ? (
                <button className="btn btn-primary btn-sm px-5 rounded-pill">
                  <Spinner color="light" size="sm" />
                </button>
              ) : (
                <>
                  {this.props.editUserData &&
                  !Object.keys(this.props.editUserData).length ? (
                    <button
                      onClick={async () => {
                        await this.createUser();
                      }}
                      className="btn btn-primary btn-sm px-5 rounded-pill font-weight-bold"
                    >
                      Add User
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={this.$userEdit}
                        className="btn btn-primary btn-sm px-5 rounded-pill font-weight-bold"
                      >
                        Edit User
                      </button>
                      <button
                        className="btn btn-dark btn-sm px-5 rounded-pill font-weight-bold"
                        onClick={() => {
                          this.props.dispatch({
                            type: "SET_USER_FOR_EDIT",
                            payload: {},
                          });
                        }}
                      >
                        Cancel Edit
                      </button>
                    </>
                  )}
                </>
              )}
            </>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  editUserData: state.userForEdit,
  companies: state.companies.list,
});

export default connect(mapStateToProps)(CreateUser);
