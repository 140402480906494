import React from "react";
import classes from "./styles/ABMFiltersHeader.module.css";
import { Select, Tooltip, DatePicker, Spin } from "antd";
import _ from "lodash";
import moment from "moment";
import downloadIcon from "../../../assest/images/download.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { REACT_APP_API_BASE_URL } = process.env;

const ABMFiltersHeader = ({
  campaigns,
  filters,
  setFilters,
  type,
  companies,
  isAdmin,
  setCompanyId,
  companyId,
  scheduleReportHandler,
  scheduleReportLoading,
  totalRecords,
  totalRecordsLoading,
}: any) => {
  const handleChange = (key: any, value: any) => {
    setFilters({
      ...filters,
      [key]: value,
      pagenumber: 1,
    });
  };

  const handleRange = (value: any, dateString: any) => {
    setFilters({
      ...filters,
      range: `${moment(dateString[0]).format("YYYYMMDD")},${moment(
        dateString[1]
      ).format("YYYYMMDD")}`,
      dayspan: "",
      pagenumber: 1,
    });
  };

  const showDateRange = () => {
    if (
      type === "ad-metrics" ||
      type === "engagement-metrics" ||
      type === "accounts-reached" ||
      type === "combined-metrics" ||
      type === "influenced-metrics"
    )
      return true;
    return false;
  };

  const token = useSelector((state: any) => state.auth.token);
  const history = useHistory();
  const location = useLocation();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.selectContainer}>
          {isAdmin ? (
            <Select
              dropdownClassName="drop_lg"
              className={classes.select}
              onChange={(value) => {
                if (type !== "abm") {
                  const params = new URLSearchParams({ type });
                  history.replace({
                    pathname: location.pathname,
                    search: params.toString(),
                  });
                }
                setCompanyId(value);
              }}
              value={companyId ? companyId : 0}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                if (typeof option?.children === "string")
                  return (
                    option?.children
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  );
                return (
                  option?.children?.props?.title
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                );
              }}
            >
              <Option value={0}>Company: All</Option>
              {companies.map((company: any, index: any) => (
                <Option value={company.id} key={index}>
                  <Tooltip title={company.name}>
                    <span>{company.name}</span>
                  </Tooltip>
                </Option>
              ))}
            </Select>
          ) : null}
          <Select
            dropdownClassName="drop_lg"
            showSearch
            className={classes.select}
            optionFilterProp="children"
            filterOption={(input, option) => {
              if (typeof option?.children === "string")
                return (
                  option?.children
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                );
              return (
                option?.children?.props?.title
                  ?.toLowerCase()
                  ?.indexOf(input?.toLowerCase()) >= 0
              );
            }}
            value={filters.campaignid}
            onChange={(value) => {
              if (type !== "abm") {
                const params = new URLSearchParams({ type, campaignid: value });
                history.replace({
                  pathname: location.pathname,
                  search: params.toString(),
                });
              }
              handleChange("campaignid", value);
            }}
          >
            {campaigns.length
              ? campaigns.map((option: any, index: any) => (
                  <Option value={option.CampaignKey.key} key={index}>
                    <Tooltip title={option.CampaignKey.keyName}>
                      <span>{option.CampaignKey.keyName}</span>
                    </Tooltip>
                  </Option>
                ))
              : null}
          </Select>
          {campaigns.length} {campaigns.length > 1 ? "Campaigns" : "Campaign"}
        </div>
        <div className={classes.dateContainer}>
          <Select
            className={classes.select}
            onChange={(value) =>
              setFilters({
                ...filters,
                dayspan: value,
                range: "",
                pagenumber: 1,
              })
            }
            value={filters.dayspan}
          >
            <Option value={""} disabled>
              {""}
            </Option>
            {daySpanOptions.map((option: any, index: any) => (
              <Option value={option.value} key={index}>
                {option.title}
              </Option>
            ))}
          </Select>

          {showDateRange() ? (
            <>
              {filters.range ? (
                <RangePicker
                  onChange={handleRange}
                  style={{ marginLeft: "20px" }}
                />
              ) : (
                <RangePicker
                  onChange={handleRange}
                  value={null}
                  style={{ marginLeft: "20px" }}
                />
              )}
            </>
          ) : null}
        </div>
      </div>
      {type === "combined-metrics" && (
        <div className={classes.downloadContainer}>
          <div className="mb-1">
            {!totalRecordsLoading && totalRecords < 10000 ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${REACT_APP_API_BASE_URL}/abm-stats/downloadCombinedMetrics/${
                  filters.campaignid
                    ? filters.campaignid
                    : campaigns?.[0]?.CampaignKey?.key
                    ? campaigns?.[0]?.CampaignKey?.key
                    : "-1"
                }?token=${token}&$where=${JSON.stringify(filters)}`}
                className="btn bg-white btn-sm rounded-pill px-4"
              >
                <img src={downloadIcon} width="10px" className="mb-1" alt="" />{" "}
                DOWNLOAD
              </a>
            ) : (
              <button
                className="btn bg-white btn-sm rounded-pill px-4"
                onClick={scheduleReportHandler}
                disabled={scheduleReportLoading || totalRecordsLoading}
                style={{ minWidth: "116px" }}
              >
                {scheduleReportLoading ? (
                  <Spin size="small" />
                ) : (
                  <>
                    <img
                      src={downloadIcon}
                      width="10px"
                      className="mb-1"
                      alt=""
                    />{" "}
                    DOWNLOAD
                  </>
                )}
              </button>
            )}
            <Link to="/abm/reports">
              <button className="btn bg-white btn-sm rounded-pill px-4">
                Reports
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

const daySpanOptions = [
  {
    title: "1 day",
    value: 1,
  },
  {
    title: "3 days",
    value: 3,
  },
  {
    title: "7 days",
    value: 7,
  },
  {
    title: "30 days",
    value: 30,
  },
  {
    title: "60 days",
    value: 60,
  },
  {
    title: "90 days",
    value: 90,
  },
  {
    title: "Last year",
    value: 365,
  },
];

export default ABMFiltersHeader;
