import React from "react";
import { Modal, Button, Input, message, Spin } from "antd";
import classes from "./styles/GeneralAudienceModal.module.css";
import { Row, Col } from "reactstrap";
import Select from "react-select";
import { createEmailAudience, editEmailAudience } from "../../api/audience";
import { fetchTargetFiles } from "../../api/target-account";

const CreateEmailAudienceModal = ({
  isModalVisible,
  handleCancel,
  setCreateAudienceModal,
  refetchHandler,
  editObj = {},
  setEditObj,
}: any) => {
  const [segmentName, setSegmentName] = React.useState("");
  const [targetFileOptions, setTargetFileOptions] = React.useState([]);
  const [targetAccountFiles, setTargetAccountFiles] = React.useState([]);
  const [loading, setLoading] = React.useState({
    audienceCreate: false,
    targetFiles: false,
  });

  const changeLoadingHandler = (key: any, value: any) => {
    setLoading((prev: any) => {
      return { ...prev, [key]: value };
    });
  };

  const isError = () => {
    if (!segmentName) {
      message.warn("Please provide Segment Name!");
      return true;
    }
    if (!targetAccountFiles) {
      message.warn("Please select Target Account File!");
      return true;
    }
    return false;
  };

  const setEditValues = () => {
    if (editObj.criteria && Object.keys(editObj.criteria).length) {
      const { targetAccountFiles = [] } = editObj.criteria;
      if (targetAccountFiles.length) setTargetAccountFiles(targetAccountFiles);
    }
    setSegmentName(editObj.segmentName);
  };

  React.useEffect(() => {
    if (Object.keys(editObj).length) {
      setEditValues();
    }
  }, []);

  const handleSubmit = async () => {
    try {
      if (isError()) return;
      changeLoadingHandler("audienceCreate", true);
      const payload = {
        segmentName,
        criteria: {
          segmentName,
          targetAccountFiles,
        },
      };
      if (Object.keys(editObj).length) {
        await editEmailAudience(editObj.id, payload);
        setEditObj({});
        message.success("Audience updated successfully");
      } else {
        await createEmailAudience(payload);
        message.success("Audience created successfully");
      }
      changeLoadingHandler("audienceCreate", false);
      setCreateAudienceModal(false);
      refetchHandler();
    } catch (error) {
      changeLoadingHandler("audienceCreate", false);
      console.log({ error });
    }
  };

  React.useEffect(() => {
    const fetchTargetFilesHandler = async () => {
      try {
        changeLoadingHandler("targetFiles", true);
        let res: any = await fetchTargetFiles("emails");
        let targetFileOptions = res.data.rows.map((value: any) => {
          return { label: value.fileName, value: value.id };
        });
        setTargetFileOptions(targetFileOptions);
        changeLoadingHandler("targetFiles", false);
      } catch (error) {
        changeLoadingHandler("targetFiles", false);

        message.error("Something went wrong");
      }
    };
    fetchTargetFilesHandler();
  }, []);

  return (
    <Modal
      centered={true}
      title="Create Site Visitor Audience"
      visible={isModalVisible}
      onOk={handleSubmit}
      onCancel={handleCancel}
      maskClosable={false}
      width={800}
      footer={
        editObj && Object.keys(editObj).length && editObj.status
          ? [
              <Button
                type="primary"
                className="btnPrimary"
                onClick={() => {
                  setEditObj({});
                  setCreateAudienceModal(false);
                }}
              >
                OK
              </Button>,
            ]
          : [
              <Button
                key="submit"
                type="primary"
                className="btnPrimary"
                onClick={handleSubmit}
                loading={loading.audienceCreate}
              >
                Submit
              </Button>,
            ]
      }
    >
      <>
        {!loading.targetFiles ? (
          <div className={classes.container}>
            <Row>
              <Col md={6}>
                <Input
                  placeholder="Enter Segment Name"
                  className={classes.inp}
                  value={segmentName}
                  onChange={({ target: { value } }) => setSegmentName(value)}
                />
              </Col>
              <Col md={6}>
                <Select
                  isMulti={true}
                  menuPlacement="auto"
                  onChange={(value: any) => {
                    setTargetAccountFiles(value);
                  }}
                  placeholder="Select Target Account File"
                  options={targetFileOptions}
                  value={(() => {
                    let options: any = [];
                    if (targetAccountFiles && targetAccountFiles.length) {
                      targetFileOptions.forEach((item: any) => {
                        targetAccountFiles.forEach((x: any) => {
                          if (item.value === x.value) {
                            options.push(item);
                          }
                        });
                      });
                      return options;
                    }
                  })()}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <div
            className="w-100 justify-content-center d-flex align-items-center bg-white"
            style={{ minHeight: "30vh" }}
          >
            <Spin size="large" />
          </div>
        )}
      </>
    </Modal>
  );
};

export default CreateEmailAudienceModal;
