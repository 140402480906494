import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import TabItem from "./Tabs/TabItem";
import { withRouter, Link } from "react-router-dom";

class TabBar extends React.Component<any, any> {
  state = {
    adminTabs: [
      // {
      //   href: "/dashboard",
      //   title: `Dashboard`,
      // },
      {
        href: "/campaigns",
        title: `Campaigns`,
      },
      {
        href: "/audience-manger",
        title: "Audience Manager",
      },
      // {
      //   href: "/account-discovery",
      //   title: "Account Discovery",
      // },
      {
        href: "/campaign-setup",
        title: "Campaign Setup",
      },
      // {
      //   href: "/dashboard",
      //   title: "Dashboard",
      // },
      {
        href: "/audience",
        title: "Audience",
      },
      {
        href: "/abm",
        title: "ABM",
      },
      {
        href: "/campaign-stats",
        title: "Campaign Stats",
      },
      {
        href: "/setup",
        title: "Setup",
        className: "float-right",
      },
    ],
    userTabs: [
      {
        href: "/setup",
        title: "Setup",
        className: "float-right",
      },
    ],
  };

  compare(a: any, b: any) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  UNSAFE_componentWillMount() {
    let permissions = this.props.auth.Role.$permissions;
    let userTabs: any = [];

    // userTabs.push({
    //   href: "/dashboard",
    //   title: `Dashboard`,
    // });
    for (const key in permissions) {
      if (key === "campaigns") {
        userTabs.push({
          href: "/campaigns",
          title: `Campaigns`,
          order: 1,
        });
        // userTabs.push({
        //   href: "/dashboard",
        //   title: `Dashboard`,
        //   order: 7,
        // });
      }
      if (key === "publishers") {
        userTabs.push({
          href: "/publishers",
          title: `Publisher View`,
          order: 2,
        });
      }
      if (key === "targetAccounts") {
        userTabs.push({
          href: "/audience-manger",
          title: "Audience Manager",
          order: 3,
        });
      }
      if (key === "accountDiscoveries") {
        // userTabs.push({
        //   href: "/account-discovery",
        //   title: "Account Discovery",
        //   order: 4,
        // });
      }
      if (key === "abmStats") {
        userTabs.push({
          href: "/abm",
          title: "ABM",
          order: 6,
        });
      }
      if (key === "campaignStats") {
        userTabs.push({
          href: "/campaign-stats",
          title: "Campaign Stats",
          order: 7,
        });
      }
      if (key === "campaignStatsAdmin") {
        userTabs.push({
          href: "/full-campaign-stats",
          title: "Full Campaign Stats",
          order: 7,
        });
      }
    }

    userTabs.push({
      href: "/setup",
      title: "Setup",
      className: "float-right",
      order: 8,
    });
    userTabs = userTabs.sort(this.compare);
    this.setState({ userTabs: userTabs });
    // this.props.history.push("/dashboard")
  }
  // componentDidUpdate(){
  //   if(this.props.auth.previousLoginState){

  //   }
  // }

  // componentDidUpdate(){}
  render() {
    const {
      location: { pathname },
    } = this.props;
    const path = /setup/i.test(pathname);
    const isCampaignSetup = pathname.includes("/campaign-setup");
    return (
      <Row className={`shdow-sm border-bottom`}>
        <Col className="d-flex px-0" id="tabBar-link">
          {this.props.auth.Role.name === "admin" ? (
            <>
              {" "}
              {this.state.adminTabs.map((tab, index) => (
                <div
                  style={tab.href === "/setup" ? { marginLeft: "auto" } : {}}
                  key={`admin_tabs_${index}`}
                >
                  {path && !isCampaignSetup && tab.href === "/setup" ? (
                    <Link
                      to="/setup/create/user"
                      className={`btn py-3 text-primary decuration-none m-0 bg-light rounded-0 shadow-none target dropdown-toggle`}
                    >
                      Setup
                    </Link>
                  ) : (
                    <TabItem
                      href={tab.href}
                      title={tab.title}
                      active={pathname === tab.href}
                    />
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              {this.state.userTabs.map((tab, index) => (
                <div
                  style={tab.href === "/setup" ? { marginLeft: "auto" } : {}}
                  key={`user_tabs_${index}`}
                >
                  {path && !isCampaignSetup && tab.href === "/setup" ? (
                    <Link
                      to="/setup/create/user"
                      className={`btn py-3 text-primary decuration-none m-0 bg-light rounded-0 shadow-none target dropdown-toggle`}
                    >
                      Setup
                    </Link>
                  ) : (
                    <TabItem
                      href={tab.href}
                      title={tab.title}
                      active={pathname === tab.href}
                    />
                  )}
                </div>
              ))}
            </>
          )}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(withRouter(TabBar));
