import React, { Component } from "react";
import { Table } from "reactstrap";
import Expression from "./Expression";
import {
  isImpressionShow,
  isClickShow,
  isLeadShow,
} from "../../utility/campaign-view";
class DigitalSubTable extends Component<any, any> {
  render() {
    let { data } = this.props;
    return (
      <div>
        <Table className="table-sm table-striped table-hover table-borderless">
          <thead>
            <tr>
              <th className="font-weight-bold border-bottom-0"></th>
              <th className="font-weight-bold border-bottom-0">Current Week</th>
              <th className="font-weight-bold border-bottom-0">
                Previous Week
              </th>
              <th className="font-weight-bold border-bottom-0">
                Current Month
              </th>
              <th className="font-weight-bold border-bottom-0">
                Previous Month
              </th>
            </tr>
          </thead>
          <tbody>
            {isImpressionShow(data) ? (
              <tr>
                <td> Impressions</td>
                <td>
                  <Expression
                    value={data.weeklyImpressionsCurrent}
                    oldValue={data.weeklyImpressionsPrevious}
                    percentAgeValue={`${data.weeklyImpressionsPercentChange}%`}
                  />
                </td>
                <td>{data.weeklyImpressionsPrevious}</td>
                <td>
                  <Expression
                    value={data.monthlyImpressionsCurrent}
                    oldValue={data.monthlyImpressionsPrevious}
                    percentAgeValue={`${data.monthlyImpressionsPercentChange}%`}
                  />
                </td>
                <td>{data.monthlyImpressionsPrevious}</td>
              </tr>
            ) : (
              ""
            )}
            {isClickShow(data) ? (
              <tr>
                <td> Clicks</td>
                <td>
                  <Expression
                    value={data.weeklyClicksCurrent}
                    oldValue={data.weeklyClicksPrevious}
                    percentAgeValue={`${data.weeklyClicksPercentChange}%`}
                  />
                </td>
                <td>{data.weeklyClicksPrevious}</td>
                <td>
                  <Expression
                    value={data.monthlyClicksCurrent}
                    oldValue={data.monthlyClicksPrevious}
                    percentAgeValue={`${data.monthlyClicksPercentChange}%`}
                  />
                </td>
                <td>{data.monthlyClicksPrevious}</td>
              </tr>
            ) : (
              ""
            )}
            {isLeadShow(data) ? (
              <tr>
                <td>Leads</td>
                <td>
                  <Expression
                    value={data.weeklyLeadsCurrent}
                    oldValue={data.weeklyLeadsPrevious}
                    percentAgeValue={`${data.weeklyLeadsPercentChange}%`}
                  />
                </td>
                <td>{data.weeklyLeadsPrevious}</td>
                <td>
                  <Expression
                    value={data.monthlyLeadsCurrent}
                    oldValue={data.monthlyLeadsPrevious}
                    percentAgeValue={`${data.monthlyLeadsPercentChange}%`}
                  />
                </td>
                <td>{data.monthlyLeadsPrevious}</td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default DigitalSubTable;
