import React from "react";

const DigitalStatsTd = ({
  data,
  isDigital = true,
}: {
  data: any;
  isDigital: any;
}) => {
  return (
    <>
      {isDigital ? (
        <td className="text-center">
          <div className="d-flex">
            <small className="d-inline-block text-truncate text-muted">
              Current Week: &nbsp;&nbsp; Impressions(
              {data.weeklyImpressionsCurrent})
            </small>
          </div>
          <div className="d-flex">
            <small className="d-inline-block text-truncate text-muted">
              Current Month: &nbsp; Impressions({data.monthlyImpressionsCurrent}
              )
            </small>
          </div>
          {data?.CampaignWinsMetric && (
            <div className="d-flex">
              <small className="d-inline-block text-truncate text-muted">
                Campaign To Date: &nbsp; Impressions(
                {data?.CampaignWinsMetric?.impcur})
              </small>
            </div>
          )}
          <div className="d-flex">
            <small className="d-inline-block text-truncate text-muted">
              Current Week: &nbsp;&nbsp; Clicks({data.weeklyClicksCurrent})
            </small>
          </div>
          <div className="d-flex">
            <small className="d-inline-block text-truncate text-muted">
              Current Month: &nbsp; Clicks({data.monthlyClicksCurrent})
            </small>
          </div>
          {data?.CampaignWinsMetric && (
            <div className="d-flex">
              <small className="d-inline-block text-truncate text-muted">
                Campaign To Date: &nbsp; Clicks(
                {data?.CampaignWinsMetric?.clickscur})
              </small>
            </div>
          )}
        </td>
      ) : (
        <td className="text-center">
          <div className="d-flex">
            <small className="d-inline-block text-truncate text-muted">
              Current Week: &nbsp;&nbsp; Leads({data.weeklyLeadsCurrent})
            </small>
          </div>
          <div className="d-flex">
            <small className="d-inline-block text-truncate text-muted">
              Current Month: &nbsp; Leads({data.monthlyLeadsCurrent})
            </small>
          </div>
          <div className="d-flex">
            <small className="d-inline-block text-truncate text-muted">
              Campaign To Date: &nbsp; Leads(
              {Math.abs(
                data.bimonthlyLeadsPrevious + data.bimonthlyLeadsCurrent
              )}
              )
            </small>
          </div>
        </td>
      )}
    </>
  );
};

export default DigitalStatsTd;
