import React from "react";
import { Typography, Select, DatePicker } from "antd";
import classes from "./styles/BwStatsHeader.module.css";
import moment from "moment";

const { Option } = Select;

const { RangePicker } = DatePicker;

const BwStatsHeader = ({
  setFilterHandler,
  filters,
  durationType,
  setDurationType,
}: any) => {
  const handleChange = (index: any) => {
    setFilterHandler("duration", options[index].value, "range");
    setDurationType({
      number: options[index].number,
      type: options[index].type,
    });
  };

  const handleRange = (value: any, dateString: any) => {
    setFilterHandler(
      "range",
      `${moment(dateString[0]).format("YYYY/MM/DD")} to ${moment(
        dateString[1]
      ).format("YYYY/MM/DD")}`,
      "duration"
    );
  };

  return (
    <div className={classes.selectContainer}>
      <Typography>
        {filters.duration ? (
          <>
            {durationType.number === 0 ? (
              <>{moment().format("ddd,MMM D,YYYY")}</>
            ) : (
              <>
                {moment()
                  .subtract(durationType.number, durationType.type)
                  .format("ddd,MMM D,YYYY")}
                -{moment().format("ddd,MMM D,YYYY")}
              </>
            )}
          </>
        ) : null}
      </Typography>
      <Select
        className={classes.select}
        onChange={handleChange}
        value={
          filters.duration
            ? options.findIndex(
                (option: any) => option.value === filters.duration
              )
            : ""
        }
      >
        <Option value={""} disabled>
          {""}
        </Option>
        {options.map((option, index) => (
          <Option value={index} key={index}>
            {option.title}
          </Option>
        ))}
      </Select>
      {filters.range ? (
        <RangePicker onChange={handleRange} />
      ) : (
        <RangePicker onChange={handleRange} value={null} />
      )}
    </div>
  );
};

const options = [
  {
    title: "Today",
    value: "past day",
    number: 0,
    type: "day",
  },
  {
    title: "Last 3 days",
    value: "3 days",
    number: 2,
    type: "day",
  },
  {
    title: "Last week",
    value: "last week",
    number: 1,
    type: "week",
  },
  {
    title: "Last month",
    value: "last month",
    number: 1,
    type: "month",
  },
  {
    title: "Last 2 months",
    value: "2 months",
    number: 2,
    type: "month",
  },
  {
    title: "Last 3 months",
    value: "3 months",
    number: 3,
    type: "month",
  },
  {
    title: "Last 6 months",
    value: "6 months",
    number: 6,
    type: "month",
  },
  {
    title: "Last year",
    value: "last year",
    number: 1,
    type: "year",
  },
];

export default BwStatsHeader;
