import { Spin } from "antd";
import React from "react";
import MetricsFiltersHeader from "./MetricsFiltersHeader";
import classes from "./styles/styles.module.css";
import AdMetrics from "./AdMetrics";
import moment from "moment";
import { getQueryParams } from "../../../utilities";
import { useHistory } from "react-router-dom";
import EngagementMetrics from "./EngagementMetrics";
import AccountsVisited from "./AccountsVisited";
import AccountsEngaged from "./AccountsEngaged";
import PageViewsMetrics from "./PageViewsMetrics";
import CombinedMetrics from "./CombinedMetrics";
import { useSelector } from "react-redux";
import { $ABM_PAGINATION_LIMIT } from "../../../configs/limitConfig";
import { scheduleReport } from "../../../api/abm-stats";
import { message } from "antd";
import { fetchTargetAccountCount } from "../../../api/target-account";
import { getAssignedCampaigns } from "../../../api/campaign-setup";
import InfluencedMetrics from "./InfluencedMetrics";

const recordsPerPage = $ABM_PAGINATION_LIMIT;

const ABMDetails = () => {
  const [type, setType] = React.useState<any>("");
  const [filters, setFilters] = React.useState<any>({
    dayspan: 7,
    range: `${moment().subtract(7, "day").format("YYYYMMDD")},${moment().format(
      "YYYYMMDD"
    )}`,
    cf_requesttrackerid: null,
    recordsperpage: recordsPerPage,
    pagenumber: 1,
    sortcol: null,
    sortorder: null,
  });
  const [campaigns, setCampaignsList] = React.useState<any>([]);
  const [tableData, setTableData] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<any>({
    tableData: false,
    campaignFetch: false,
    scheduleReport: false,
    totalRecordsCalc: false,
  });
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [companyId, setCompanyId] = React.useState(0);
  const companies = useSelector((state: any) => state.companies.list);
  const isAdmin = useSelector((state: any) => state.auth.role === "admin");
  const history = useHistory();

  const changeLoadingHandler = (key: any, value: any) => {
    setLoading((prev: any) => {
      return { ...prev, [key]: value };
    });
  };
  const fetchCampaignsHandler = async () => {
    try {
      setCampaignsList([]);
      setFilters({
        ...filters,
        dayspan: 7,
        range: `${moment()
          .subtract(7, "day")
          .format("YYYYMMDD")},${moment().format("YYYYMMDD")}`,
        pagenumber: 1,
        sortcol: null,
        sortorder: null,
      });
      changeLoadingHandler("campaignFetch", true);
      let res = companyId
        ? await getAssignedCampaigns(companyId)
        : await getAssignedCampaigns();
      setCampaignsList(res.data && res.data.length ? [...res.data] : []);
      changeLoadingHandler("campaignFetch", false);
    } catch (error) {
      changeLoadingHandler("campaignFetch", false);
      console.log({ error });
    }
  };

  React.useEffect(() => {
    const queryParamsObj: any = getQueryParams(window.location.search);
    if (!queryParamsObj.type) history.push("/abm");
    setType(queryParamsObj.type);
  }, []);

  const setCampaignIdHandler = () => {
    const queryParamsObj: any = getQueryParams(window.location.search);
    const campaignid = queryParamsObj.campaignId;
    const temp = filters;
    if (campaignid) {
      temp["campaignid"] = campaignid;
      setFilters({ ...temp });
    } else {
      temp["campaignid"] = campaigns?.[0]?.CampaignKey?.key
        ? campaigns?.[0]?.CampaignKey?.key
        : "-1";
      setFilters({ ...temp });
    }
  };

  React.useEffect(() => {
    fetchCampaignsHandler();
    setTableData([]);
  }, [companyId]);

  React.useEffect(() => {
    if (campaigns && campaigns.length) {
      setCampaignIdHandler();
    }
  }, [campaigns]);

  const formatWinsMetricsInput = () => {
    const obj: any = {};
    filters["campaign_id"]
      ? (obj["campaignid"] = filters.campaign_id)
      : (obj["campaignid"] = campaigns[0].campaign_id);

    obj["recordsperpage"] = recordsPerPage;
    obj["pagenumber"] = 1;
    if (filters.dateRange) {
      const fromDate = filters.dateRange.split(",")[0];
      const toDate = filters.dateRange.split(",")[1];
      obj["startdateyyyymmdd"] = moment(fromDate).format("YYYYMMDD");
      obj["enddateyyyymmdd"] = moment(toDate).format("YYYYMMDD");
    }
    console.log({ obj });
    return obj;
  };

  const filterChangeHandler = (obj: any) => {
    setFilters({ ...filters, ...obj });
  };

  const scheduleReportHandler = async (e: any) => {
    e.preventDefault();
    try {
      changeLoadingHandler("scheduleReport", true);
      const campaignId: any = filters.campaignid
        ? filters.campaignid
        : campaigns?.[0]?.CampaignKey?.key
        ? campaigns?.[0]?.CampaignKey?.key
        : "-1";
      await scheduleReport({ filters, campaignId });
      message.success("Report has been scheduled!");
      changeLoadingHandler("scheduleReport", false);
      history.push("/abm/reports");
    } catch (error) {
      if (error?.response?.data?.message === "Report already exists!") {
        message.info("This Report already exists!");
      } else {
        message.error("Something went wrong!");
      }
      changeLoadingHandler("scheduleReport", false);
      console.log({ error });
    }
  };

  const getCampaignKeyId = () => {
    if (!filters.campaignid) {
      return campaigns?.[0]?.CampaignKey?.id;
    } else {
      const campaign = campaigns?.find(
        (item: any) => item?.CampaignKey?.key === filters.campaignid
      );
      return campaign?.CampaignKey?.id;
    }
  };

  const fetchTargetAccountCountHandler = async () => {
    try {
      changeLoadingHandler("totalRecordsCalc", true);
      const campaignKeyId = getCampaignKeyId();
      const res = await fetchTargetAccountCount(campaignKeyId);
      const records = res?.data?.totalProcessedRecords ?? "0";
      setTotalRecords(parseInt(records));
      changeLoadingHandler("totalRecordsCalc", false);
    } catch (error) {
      changeLoadingHandler("totalRecordsCalc", false);
      console.log({ error });
    }
  };

  React.useEffect(() => {
    if (campaigns && campaigns.length) {
      fetchTargetAccountCountHandler();
    }
  }, [campaigns, filters]);

  return (
    <>
      {!loading.campaignFetch ? (
        <div className={classes.container}>
          <MetricsFiltersHeader
            campaigns={campaigns}
            setFilters={setFilters}
            filters={filters}
            type={type}
            companies={companies}
            isAdmin={isAdmin}
            setCompanyId={setCompanyId}
            companyId={companyId}
            scheduleReportHandler={scheduleReportHandler}
            scheduleReportLoading={loading.scheduleReport}
            totalRecords={totalRecords}
            totalRecordsLoading={loading.totalRecordsCalc}
          />
          {(type === "ad-metrics" || type === "accounts-reached") && (
            <AdMetrics
              setTableData={setTableData}
              tableData={tableData}
              changeLoadingHandler={changeLoadingHandler}
              campaigns={campaigns}
              filters={filters}
              loading={loading.tableData}
              filterChangeHandler={filterChangeHandler}
            />
          )}
          {type === "combined-metrics" && (
            <CombinedMetrics
              setTableData={setTableData}
              tableData={tableData}
              changeLoadingHandler={changeLoadingHandler}
              campaigns={campaigns}
              filters={filters}
              loading={loading.tableData}
              filterChangeHandler={filterChangeHandler}
            />
          )}
          {type === "influenced-metrics" && (
            <InfluencedMetrics
              setTableData={setTableData}
              tableData={tableData}
              changeLoadingHandler={changeLoadingHandler}
              campaigns={campaigns}
              filters={filters}
              loading={loading.tableData}
              filterChangeHandler={filterChangeHandler}
            />
          )}
          {type === "engagement-metrics" && (
            <EngagementMetrics
              setTableData={setTableData}
              tableData={tableData}
              changeLoadingHandler={changeLoadingHandler}
              campaigns={campaigns}
              filters={filters}
              loading={loading.tableData}
              filterChangeHandler={filterChangeHandler}
            />
          )}
          {(type === "accounts-visited" || type === "unique-visitors") && (
            <AccountsVisited
              setTableData={setTableData}
              tableData={tableData}
              changeLoadingHandler={changeLoadingHandler}
              campaigns={campaigns}
              filters={filters}
              loading={loading.tableData}
              filterChangeHandler={filterChangeHandler}
            />
          )}
          {(type === "accounts-engaged" || type === "engaged-visitors") && (
            <AccountsEngaged
              setTableData={setTableData}
              tableData={tableData}
              changeLoadingHandler={changeLoadingHandler}
              campaigns={campaigns}
              filters={filters}
              loading={loading.tableData}
              filterChangeHandler={filterChangeHandler}
            />
          )}
          {type === "page-views" && (
            <PageViewsMetrics
              setTableData={setTableData}
              tableData={tableData}
              changeLoadingHandler={changeLoadingHandler}
              campaigns={campaigns}
              filters={filters}
              loading={loading.tableData}
              filterChangeHandler={filterChangeHandler}
            />
          )}
        </div>
      ) : (
        <div
          className="w-100 justify-content-center d-flex align-items-center bg-white"
          style={{ minHeight: "75vh" }}
        >
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default ABMDetails;
